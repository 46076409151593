export const DocumentBuilderDeductedInvoices = () => import('../../components/document/builder/total/DocumentBuilderDeductedInvoices.vue' /* webpackChunkName: "components/document-builder-deducted-invoices" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderDeductions = () => import('../../components/document/builder/total/DocumentBuilderDeductions.vue' /* webpackChunkName: "components/document-builder-deductions" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderDownPaymentsDeductionModal = () => import('../../components/document/builder/total/DocumentBuilderDownPaymentsDeductionModal.vue' /* webpackChunkName: "components/document-builder-down-payments-deduction-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderSubtotalDeductionsModal = () => import('../../components/document/builder/total/DocumentBuilderSubtotalDeductionsModal.vue' /* webpackChunkName: "components/document-builder-subtotal-deductions-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderTotalDeductions = () => import('../../components/document/builder/total/DocumentBuilderTotalDeductions.vue' /* webpackChunkName: "components/document-builder-total-deductions" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderTotalDeductionsModal = () => import('../../components/document/builder/total/DocumentBuilderTotalDeductionsModal.vue' /* webpackChunkName: "components/document-builder-total-deductions-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderTotalOrderForm = () => import('../../components/document/builder/total/DocumentBuilderTotalOrderForm.vue' /* webpackChunkName: "components/document-builder-total-order-form" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderTotalSimple = () => import('../../components/document/builder/total/DocumentBuilderTotalSimple.vue' /* webpackChunkName: "components/document-builder-total-simple" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderTotalSimpleTaxable = () => import('../../components/document/builder/total/DocumentBuilderTotalSimpleTaxable.vue' /* webpackChunkName: "components/document-builder-total-simple-taxable" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderHelperGroupModal = () => import('../../components/document/builder/helper/DocumentBuilderHelperGroupModal.vue' /* webpackChunkName: "components/document-builder-helper-group-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderHelperRevisionHint = () => import('../../components/document/builder/helper/DocumentBuilderHelperRevisionHint.vue' /* webpackChunkName: "components/document-builder-helper-revision-hint" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsDeductions = () => import('../../components/document/details/total/DocumentDetailsDeductions.vue' /* webpackChunkName: "components/document-details-deductions" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTotalDeducted = () => import('../../components/document/details/total/DocumentDetailsTotalDeducted.vue' /* webpackChunkName: "components/document-details-total-deducted" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTotalDeductions = () => import('../../components/document/details/total/DocumentDetailsTotalDeductions.vue' /* webpackChunkName: "components/document-details-total-deductions" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTotalSimple = () => import('../../components/document/details/total/DocumentDetailsTotalSimple.vue' /* webpackChunkName: "components/document-details-total-simple" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTotalSimpleTaxable = () => import('../../components/document/details/total/DocumentDetailsTotalSimpleTaxable.vue' /* webpackChunkName: "components/document-details-total-simple-taxable" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewTemplate02Head = () => import('../../components/document/preview/templates/02/Head.vue' /* webpackChunkName: "components/document-preview-template02-head" */).then(c => wrapFunctional(c.default || c))
export const LibraryBC = () => import('../../components/library/BC/LibraryBC.vue' /* webpackChunkName: "components/library-b-c" */).then(c => wrapFunctional(c.default || c))
export const LibraryBCCategory = () => import('../../components/library/BC/LibraryBCCategory.vue' /* webpackChunkName: "components/library-b-c-category" */).then(c => wrapFunctional(c.default || c))
export const LibraryBCCategoryLink = () => import('../../components/library/BC/LibraryBCCategoryLink.vue' /* webpackChunkName: "components/library-b-c-category-link" */).then(c => wrapFunctional(c.default || c))
export const LibraryBCContainer = () => import('../../components/library/BC/LibraryBCContainer.vue' /* webpackChunkName: "components/library-b-c-container" */).then(c => wrapFunctional(c.default || c))
export const LibraryBCContainerModal = () => import('../../components/library/BC/LibraryBCContainerModal.vue' /* webpackChunkName: "components/library-b-c-container-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryBCDemo = () => import('../../components/library/BC/LibraryBCDemo.vue' /* webpackChunkName: "components/library-b-c-demo" */).then(c => wrapFunctional(c.default || c))
export const LibraryBCModal = () => import('../../components/library/BC/LibraryBCModal.vue' /* webpackChunkName: "components/library-b-c-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryBCProduct = () => import('../../components/library/BC/LibraryBCProduct.vue' /* webpackChunkName: "components/library-b-c-product" */).then(c => wrapFunctional(c.default || c))
export const LibraryBPCategory = () => import('../../components/library/BP/LibraryBPCategory.vue' /* webpackChunkName: "components/library-b-p-category" */).then(c => wrapFunctional(c.default || c))
export const LibraryBPCategoryLink = () => import('../../components/library/BP/LibraryBPCategoryLink.vue' /* webpackChunkName: "components/library-b-p-category-link" */).then(c => wrapFunctional(c.default || c))
export const LibraryBPConfig = () => import('../../components/library/BP/LibraryBPConfig.vue' /* webpackChunkName: "components/library-b-p-config" */).then(c => wrapFunctional(c.default || c))
export const LibraryBPContainer = () => import('../../components/library/BP/LibraryBPContainer.vue' /* webpackChunkName: "components/library-b-p-container" */).then(c => wrapFunctional(c.default || c))
export const LibraryBPContainerModal = () => import('../../components/library/BP/LibraryBPContainerModal.vue' /* webpackChunkName: "components/library-b-p-container-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryBPProduct = () => import('../../components/library/BP/LibraryBPProduct.vue' /* webpackChunkName: "components/library-b-p-product" */).then(c => wrapFunctional(c.default || c))
export const LibraryLegrandCategory = () => import('../../components/library/Legrand/LibraryLegrandCategory.vue' /* webpackChunkName: "components/library-legrand-category" */).then(c => wrapFunctional(c.default || c))
export const LibraryLegrandCategoryLink = () => import('../../components/library/Legrand/LibraryLegrandCategoryLink.vue' /* webpackChunkName: "components/library-legrand-category-link" */).then(c => wrapFunctional(c.default || c))
export const LibraryLegrandConfig = () => import('../../components/library/Legrand/LibraryLegrandConfig.vue' /* webpackChunkName: "components/library-legrand-config" */).then(c => wrapFunctional(c.default || c))
export const LibraryLegrandContainer = () => import('../../components/library/Legrand/LibraryLegrandContainer.vue' /* webpackChunkName: "components/library-legrand-container" */).then(c => wrapFunctional(c.default || c))
export const LibraryLegrandContainerModal = () => import('../../components/library/Legrand/LibraryLegrandContainerModal.vue' /* webpackChunkName: "components/library-legrand-container-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryLegrandProduct = () => import('../../components/library/Legrand/LibraryLegrandProduct.vue' /* webpackChunkName: "components/library-legrand-product" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilder = () => import('../../components/document/builder/DocumentBuilder.vue' /* webpackChunkName: "components/document-builder" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderAddLine = () => import('../../components/document/builder/DocumentBuilderAddLine.vue' /* webpackChunkName: "components/document-builder-add-line" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderAssignee = () => import('../../components/document/builder/DocumentBuilderAssignee.vue' /* webpackChunkName: "components/document-builder-assignee" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderBonus = () => import('../../components/document/builder/DocumentBuilderBonus.vue' /* webpackChunkName: "components/document-builder-bonus" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderCancelConfirmModal = () => import('../../components/document/builder/DocumentBuilderCancelConfirmModal.vue' /* webpackChunkName: "components/document-builder-cancel-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderContainer = () => import('../../components/document/builder/DocumentBuilderContainer.vue' /* webpackChunkName: "components/document-builder-container" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderCustomer = () => import('../../components/document/builder/DocumentBuilderCustomer.vue' /* webpackChunkName: "components/document-builder-customer" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderDeliveryAddress = () => import('../../components/document/builder/DocumentBuilderDeliveryAddress.vue' /* webpackChunkName: "components/document-builder-delivery-address" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderDetachFromQuoteModal = () => import('../../components/document/builder/DocumentBuilderDetachFromQuoteModal.vue' /* webpackChunkName: "components/document-builder-detach-from-quote-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderDiscount = () => import('../../components/document/builder/DocumentBuilderDiscount.vue' /* webpackChunkName: "components/document-builder-discount" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderDownPayments = () => import('../../components/document/builder/DocumentBuilderDownPayments.vue' /* webpackChunkName: "components/document-builder-down-payments" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderDownPaymentsModal = () => import('../../components/document/builder/DocumentBuilderDownPaymentsModal.vue' /* webpackChunkName: "components/document-builder-down-payments-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderEditReasonModal = () => import('../../components/document/builder/DocumentBuilderEditReasonModal.vue' /* webpackChunkName: "components/document-builder-edit-reason-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderExpireAt = () => import('../../components/document/builder/DocumentBuilderExpireAt.vue' /* webpackChunkName: "components/document-builder-expire-at" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderFormPaymentsModal = () => import('../../components/document/builder/DocumentBuilderFormPaymentsModal.vue' /* webpackChunkName: "components/document-builder-form-payments-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderGlobalSituation = () => import('../../components/document/builder/DocumentBuilderGlobalSituation.vue' /* webpackChunkName: "components/document-builder-global-situation" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderHoldback = () => import('../../components/document/builder/DocumentBuilderHoldback.vue' /* webpackChunkName: "components/document-builder-holdback" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderIntersection = () => import('../../components/document/builder/DocumentBuilderIntersection.vue' /* webpackChunkName: "components/document-builder-intersection" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderInvoiceProject = () => import('../../components/document/builder/DocumentBuilderInvoiceProject.vue' /* webpackChunkName: "components/document-builder-invoice-project" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineAction = () => import('../../components/document/builder/DocumentBuilderLineAction.vue' /* webpackChunkName: "components/document-builder-line-action" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineActionInsert = () => import('../../components/document/builder/DocumentBuilderLineActionInsert.vue' /* webpackChunkName: "components/document-builder-line-action-insert" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineBottomToolbar = () => import('../../components/document/builder/DocumentBuilderLineBottomToolbar.vue' /* webpackChunkName: "components/document-builder-line-bottom-toolbar" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineBreak = () => import('../../components/document/builder/DocumentBuilderLineBreak.vue' /* webpackChunkName: "components/document-builder-line-break" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineDiscount = () => import('../../components/document/builder/DocumentBuilderLineDiscount.vue' /* webpackChunkName: "components/document-builder-line-discount" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineGroup = () => import('../../components/document/builder/DocumentBuilderLineGroup.vue' /* webpackChunkName: "components/document-builder-line-group" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineGroupImmutable = () => import('../../components/document/builder/DocumentBuilderLineGroupImmutable.vue' /* webpackChunkName: "components/document-builder-line-group-immutable" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineGroupSituation = () => import('../../components/document/builder/DocumentBuilderLineGroupSituation.vue' /* webpackChunkName: "components/document-builder-line-group-situation" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineImages = () => import('../../components/document/builder/DocumentBuilderLineImages.vue' /* webpackChunkName: "components/document-builder-line-images" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineMobileDiscount = () => import('../../components/document/builder/DocumentBuilderLineMobileDiscount.vue' /* webpackChunkName: "components/document-builder-line-mobile-discount" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineMobileProduct = () => import('../../components/document/builder/DocumentBuilderLineMobileProduct.vue' /* webpackChunkName: "components/document-builder-line-mobile-product" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineMobileProductImmutable = () => import('../../components/document/builder/DocumentBuilderLineMobileProductImmutable.vue' /* webpackChunkName: "components/document-builder-line-mobile-product-immutable" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineMobileProductOrderForm = () => import('../../components/document/builder/DocumentBuilderLineMobileProductOrderForm.vue' /* webpackChunkName: "components/document-builder-line-mobile-product-order-form" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineMobileProductSituation = () => import('../../components/document/builder/DocumentBuilderLineMobileProductSituation.vue' /* webpackChunkName: "components/document-builder-line-mobile-product-situation" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineMobileText = () => import('../../components/document/builder/DocumentBuilderLineMobileText.vue' /* webpackChunkName: "components/document-builder-line-mobile-text" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineMobileTextImmutable = () => import('../../components/document/builder/DocumentBuilderLineMobileTextImmutable.vue' /* webpackChunkName: "components/document-builder-line-mobile-text-immutable" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineModal = () => import('../../components/document/builder/DocumentBuilderLineModal.vue' /* webpackChunkName: "components/document-builder-line-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLinePageBreak = () => import('../../components/document/builder/DocumentBuilderLinePageBreak.vue' /* webpackChunkName: "components/document-builder-line-page-break" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineProduct = () => import('../../components/document/builder/DocumentBuilderLineProduct.vue' /* webpackChunkName: "components/document-builder-line-product" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineProductBuyPrice = () => import('../../components/document/builder/DocumentBuilderLineProductBuyPrice.vue' /* webpackChunkName: "components/document-builder-line-product-buy-price" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineProductImmutable = () => import('../../components/document/builder/DocumentBuilderLineProductImmutable.vue' /* webpackChunkName: "components/document-builder-line-product-immutable" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineProductOrderForm = () => import('../../components/document/builder/DocumentBuilderLineProductOrderForm.vue' /* webpackChunkName: "components/document-builder-line-product-order-form" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineProductSearch = () => import('../../components/document/builder/DocumentBuilderLineProductSearch.vue' /* webpackChunkName: "components/document-builder-line-product-search" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineProductSituation = () => import('../../components/document/builder/DocumentBuilderLineProductSituation.vue' /* webpackChunkName: "components/document-builder-line-product-situation" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineProductWorkItems = () => import('../../components/document/builder/DocumentBuilderLineProductWorkItems.vue' /* webpackChunkName: "components/document-builder-line-product-work-items" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineSituationAction = () => import('../../components/document/builder/DocumentBuilderLineSituationAction.vue' /* webpackChunkName: "components/document-builder-line-situation-action" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineSubtotal = () => import('../../components/document/builder/DocumentBuilderLineSubtotal.vue' /* webpackChunkName: "components/document-builder-line-subtotal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineText = () => import('../../components/document/builder/DocumentBuilderLineText.vue' /* webpackChunkName: "components/document-builder-line-text" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineTextImmutable = () => import('../../components/document/builder/DocumentBuilderLineTextImmutable.vue' /* webpackChunkName: "components/document-builder-line-text-immutable" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineWorkAdjustModal = () => import('../../components/document/builder/DocumentBuilderLineWorkAdjustModal.vue' /* webpackChunkName: "components/document-builder-line-work-adjust-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineWorkItems = () => import('../../components/document/builder/DocumentBuilderLineWorkItems.vue' /* webpackChunkName: "components/document-builder-line-work-items" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineWorkItemsDetailed = () => import('../../components/document/builder/DocumentBuilderLineWorkItemsDetailed.vue' /* webpackChunkName: "components/document-builder-line-work-items-detailed" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineWorkItemsEditModal = () => import('../../components/document/builder/DocumentBuilderLineWorkItemsEditModal.vue' /* webpackChunkName: "components/document-builder-line-work-items-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderLineWorkItemsLine = () => import('../../components/document/builder/DocumentBuilderLineWorkItemsLine.vue' /* webpackChunkName: "components/document-builder-line-work-items-line" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderMobileTableHead = () => import('../../components/document/builder/DocumentBuilderMobileTableHead.vue' /* webpackChunkName: "components/document-builder-mobile-table-head" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderOpenConfirmModal = () => import('../../components/document/builder/DocumentBuilderOpenConfirmModal.vue' /* webpackChunkName: "components/document-builder-open-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderOpenModal = () => import('../../components/document/builder/DocumentBuilderOpenModal.vue' /* webpackChunkName: "components/document-builder-open-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderParameterDuration = () => import('../../components/document/builder/DocumentBuilderParameterDuration.vue' /* webpackChunkName: "components/document-builder-parameter-duration" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderParameterField = () => import('../../components/document/builder/DocumentBuilderParameterField.vue' /* webpackChunkName: "components/document-builder-parameter-field" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderParametersModal = () => import('../../components/document/builder/DocumentBuilderParametersModal.vue' /* webpackChunkName: "components/document-builder-parameters-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderParametersModalSplitOptions = () => import('../../components/document/builder/DocumentBuilderParametersModal_split_options.vue' /* webpackChunkName: "components/document-builder-parameters-modal-split-options" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderPaymentConditions = () => import('../../components/document/builder/DocumentBuilderPaymentConditions.vue' /* webpackChunkName: "components/document-builder-payment-conditions" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderPaymentMethods = () => import('../../components/document/builder/DocumentBuilderPaymentMethods.vue' /* webpackChunkName: "components/document-builder-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderPaymentMethodsModal = () => import('../../components/document/builder/DocumentBuilderPaymentMethodsModal.vue' /* webpackChunkName: "components/document-builder-payment-methods-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderProfit = () => import('../../components/document/builder/DocumentBuilderProfit.vue' /* webpackChunkName: "components/document-builder-profit" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderProject = () => import('../../components/document/builder/DocumentBuilderProject.vue' /* webpackChunkName: "components/document-builder-project" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderReverseChargeModal = () => import('../../components/document/builder/DocumentBuilderReverseChargeModal.vue' /* webpackChunkName: "components/document-builder-reverse-charge-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderSideToolbar = () => import('../../components/document/builder/DocumentBuilderSideToolbar.vue' /* webpackChunkName: "components/document-builder-side-toolbar" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderSituationHistory = () => import('../../components/document/builder/DocumentBuilderSituationHistory.vue' /* webpackChunkName: "components/document-builder-situation-history" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderSupplier = () => import('../../components/document/builder/DocumentBuilderSupplier.vue' /* webpackChunkName: "components/document-builder-supplier" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderTableHead = () => import('../../components/document/builder/DocumentBuilderTableHead.vue' /* webpackChunkName: "components/document-builder-table-head" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderUpdatePricesModal = () => import('../../components/document/builder/DocumentBuilderUpdatePricesModal.vue' /* webpackChunkName: "components/document-builder-update-prices-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderVatNoticeModal = () => import('../../components/document/builder/DocumentBuilderVatNoticeModal.vue' /* webpackChunkName: "components/document-builder-vat-notice-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderWorkItemsDetailed = () => import('../../components/document/builder/DocumentBuilderWorkItemsDetailed.vue' /* webpackChunkName: "components/document-builder-work-items-detailed" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderWorkItemsLine = () => import('../../components/document/builder/DocumentBuilderWorkItemsLine.vue' /* webpackChunkName: "components/document-builder-work-items-line" */).then(c => wrapFunctional(c.default || c))
export const DocumentCoverContainer = () => import('../../components/document/files/DocumentCoverContainer.vue' /* webpackChunkName: "components/document-cover-container" */).then(c => wrapFunctional(c.default || c))
export const DocumentDefaultFilesContainer = () => import('../../components/document/files/DocumentDefaultFilesContainer.vue' /* webpackChunkName: "components/document-default-files-container" */).then(c => wrapFunctional(c.default || c))
export const DocumentFilesContainer = () => import('../../components/document/files/DocumentFilesContainer.vue' /* webpackChunkName: "components/document-files-container" */).then(c => wrapFunctional(c.default || c))
export const DocumentFilesContainerModal = () => import('../../components/document/files/DocumentFilesContainerModal.vue' /* webpackChunkName: "components/document-files-container-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsAcceptanceStatus = () => import('../../components/document/details/DocumentDetailsAcceptanceStatus.vue' /* webpackChunkName: "components/document-details-acceptance-status" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsAcceptedModal = () => import('../../components/document/details/DocumentDetailsAcceptedModal.vue' /* webpackChunkName: "components/document-details-accepted-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsAttachedFiles = () => import('../../components/document/details/DocumentDetailsAttachedFiles.vue' /* webpackChunkName: "components/document-details-attached-files" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsAttachments = () => import('../../components/document/details/DocumentDetailsAttachments.vue' /* webpackChunkName: "components/document-details-attachments" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsChildCredits = () => import('../../components/document/details/DocumentDetailsChildCredits.vue' /* webpackChunkName: "components/document-details-child-credits" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsChildInvoices = () => import('../../components/document/details/DocumentDetailsChildInvoices.vue' /* webpackChunkName: "components/document-details-child-invoices" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsCreditActions = () => import('../../components/document/details/DocumentDetailsCreditActions.vue' /* webpackChunkName: "components/document-details-credit-actions" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsCustomer = () => import('../../components/document/details/DocumentDetailsCustomer.vue' /* webpackChunkName: "components/document-details-customer" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsElectronicSignatureModal = () => import('../../components/document/details/DocumentDetailsElectronicSignatureModal.vue' /* webpackChunkName: "components/document-details-electronic-signature-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsEventsList = () => import('../../components/document/details/DocumentDetailsEventsList.vue' /* webpackChunkName: "components/document-details-events-list" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsInvoiceActions = () => import('../../components/document/details/DocumentDetailsInvoiceActions.vue' /* webpackChunkName: "components/document-details-invoice-actions" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLineGroup = () => import('../../components/document/details/DocumentDetailsLineGroup.vue' /* webpackChunkName: "components/document-details-line-group" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLineProduct = () => import('../../components/document/details/DocumentDetailsLineProduct.vue' /* webpackChunkName: "components/document-details-line-product" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLineProductSituation = () => import('../../components/document/details/DocumentDetailsLineProductSituation.vue' /* webpackChunkName: "components/document-details-line-product-situation" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLineSituationWorkItemsLine = () => import('../../components/document/details/DocumentDetailsLineSituationWorkItemsLine.vue' /* webpackChunkName: "components/document-details-line-situation-work-items-line" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLineSubtotal = () => import('../../components/document/details/DocumentDetailsLineSubtotal.vue' /* webpackChunkName: "components/document-details-line-subtotal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLineText = () => import('../../components/document/details/DocumentDetailsLineText.vue' /* webpackChunkName: "components/document-details-line-text" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLineWorkItemsLine = () => import('../../components/document/details/DocumentDetailsLineWorkItemsLine.vue' /* webpackChunkName: "components/document-details-line-work-items-line" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLinkAssigneeModal = () => import('../../components/document/details/DocumentDetailsLinkAssigneeModal.vue' /* webpackChunkName: "components/document-details-link-assignee-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsLinkProjectModal = () => import('../../components/document/details/DocumentDetailsLinkProjectModal.vue' /* webpackChunkName: "components/document-details-link-project-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsMeta = () => import('../../components/document/details/DocumentDetailsMeta.vue' /* webpackChunkName: "components/document-details-meta" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsMetaItem = () => import('../../components/document/details/DocumentDetailsMetaItem.vue' /* webpackChunkName: "components/document-details-meta-item" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsMoreActionsDropdown = () => import('../../components/document/details/DocumentDetailsMoreActionsDropdown.vue' /* webpackChunkName: "components/document-details-more-actions-dropdown" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsName = () => import('../../components/document/details/DocumentDetailsName.vue' /* webpackChunkName: "components/document-details-name" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsNameModal = () => import('../../components/document/details/DocumentDetailsNameModal.vue' /* webpackChunkName: "components/document-details-name-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsNotFound = () => import('../../components/document/details/DocumentDetailsNotFound.vue' /* webpackChunkName: "components/document-details-not-found" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsOpenModal = () => import('../../components/document/details/DocumentDetailsOpenModal.vue' /* webpackChunkName: "components/document-details-open-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsOrderFormActions = () => import('../../components/document/details/DocumentDetailsOrderFormActions.vue' /* webpackChunkName: "components/document-details-order-form-actions" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsOrderFormStatus = () => import('../../components/document/details/DocumentDetailsOrderFormStatus.vue' /* webpackChunkName: "components/document-details-order-form-status" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsPayments = () => import('../../components/document/details/DocumentDetailsPayments.vue' /* webpackChunkName: "components/document-details-payments" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsPickTable = () => import('../../components/document/details/DocumentDetailsPickTable.vue' /* webpackChunkName: "components/document-details-pick-table" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsProfit = () => import('../../components/document/details/DocumentDetailsProfit.vue' /* webpackChunkName: "components/document-details-profit" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsPurchaseOrderNumberModal = () => import('../../components/document/details/DocumentDetailsPurchaseOrderNumberModal.vue' /* webpackChunkName: "components/document-details-purchase-order-number-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsQuoteActions = () => import('../../components/document/details/DocumentDetailsQuoteActions.vue' /* webpackChunkName: "components/document-details-quote-actions" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsSignModal = () => import('../../components/document/details/DocumentDetailsSignModal.vue' /* webpackChunkName: "components/document-details-sign-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTable = () => import('../../components/document/details/DocumentDetailsTable.vue' /* webpackChunkName: "components/document-details-table" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTimeline = () => import('../../components/document/details/DocumentDetailsTimeline.vue' /* webpackChunkName: "components/document-details-timeline" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTimelineStep = () => import('../../components/document/details/DocumentDetailsTimelineStep.vue' /* webpackChunkName: "components/document-details-timeline-step" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTimelineStepComplete = () => import('../../components/document/details/DocumentDetailsTimelineStepComplete.vue' /* webpackChunkName: "components/document-details-timeline-step-complete" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTimelineStepCompleted = () => import('../../components/document/details/DocumentDetailsTimelineStepCompleted.vue' /* webpackChunkName: "components/document-details-timeline-step-completed" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTimelineStepPending = () => import('../../components/document/details/DocumentDetailsTimelineStepPending.vue' /* webpackChunkName: "components/document-details-timeline-step-pending" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsTitle = () => import('../../components/document/details/DocumentDetailsTitle.vue' /* webpackChunkName: "components/document-details-title" */).then(c => wrapFunctional(c.default || c))
export const DocumentSignaturePad = () => import('../../components/document/details/DocumentSignaturePad.vue' /* webpackChunkName: "components/document-signature-pad" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreview = () => import('../../components/document/preview/DocumentPreview.vue' /* webpackChunkName: "components/document-preview" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewDownPayments = () => import('../../components/document/preview/DocumentPreviewDownPayments.vue' /* webpackChunkName: "components/document-preview-down-payments" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewEditBubble = () => import('../../components/document/preview/DocumentPreviewEditBubble.vue' /* webpackChunkName: "components/document-preview-edit-bubble" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewFormCompanyModal = () => import('../../components/document/preview/DocumentPreviewFormCompanyModal.vue' /* webpackChunkName: "components/document-preview-form-company-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewFormLegalModal = () => import('../../components/document/preview/DocumentPreviewFormLegalModal.vue' /* webpackChunkName: "components/document-preview-form-legal-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewLabels = () => import('../../components/document/preview/DocumentPreviewLabels.vue' /* webpackChunkName: "components/document-preview-labels" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewPaymentMethods = () => import('../../components/document/preview/DocumentPreviewPaymentMethods.vue' /* webpackChunkName: "components/document-preview-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewPdf = () => import('../../components/document/preview/DocumentPreviewPdf.vue' /* webpackChunkName: "components/document-preview-pdf" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewPdfModal = () => import('../../components/document/preview/DocumentPreviewPdfModal.vue' /* webpackChunkName: "components/document-preview-pdf-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewTableEmpty = () => import('../../components/document/preview/DocumentPreviewTableEmpty.vue' /* webpackChunkName: "components/document-preview-table-empty" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewTableHeader = () => import('../../components/document/preview/DocumentPreviewTableHeader.vue' /* webpackChunkName: "components/document-preview-table-header" */).then(c => wrapFunctional(c.default || c))
export const DocumentPreviewTemplatingSpaceholder = () => import('../../components/document/preview/DocumentPreviewTemplatingSpaceholder.vue' /* webpackChunkName: "components/document-preview-templating-spaceholder" */).then(c => wrapFunctional(c.default || c))
export const DocumentVatAttestation = () => import('../../components/document/preview/DocumentVatAttestation.vue' /* webpackChunkName: "components/document-vat-attestation" */).then(c => wrapFunctional(c.default || c))
export const DocumentVatAttestationSideToolbar = () => import('../../components/document/preview/DocumentVatAttestationSideToolbar.vue' /* webpackChunkName: "components/document-vat-attestation-side-toolbar" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/layout/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const BuilderBuyPriceField = () => import('../../components/layout/BuilderBuyPriceField.vue' /* webpackChunkName: "components/builder-buy-price-field" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/layout/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/layout/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CompaniesSuggestions = () => import('../../components/layout/CompaniesSuggestions.vue' /* webpackChunkName: "components/companies-suggestions" */).then(c => wrapFunctional(c.default || c))
export const CompanySidebar = () => import('../../components/layout/CompanySidebar.vue' /* webpackChunkName: "components/company-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ConfirmNonTaxableSwitchModal = () => import('../../components/layout/ConfirmNonTaxableSwitchModal.vue' /* webpackChunkName: "components/confirm-non-taxable-switch-modal" */).then(c => wrapFunctional(c.default || c))
export const ContactTypeBadge = () => import('../../components/layout/ContactTypeBadge.vue' /* webpackChunkName: "components/contact-type-badge" */).then(c => wrapFunctional(c.default || c))
export const CountryDropdown = () => import('../../components/layout/CountryDropdown.vue' /* webpackChunkName: "components/country-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/layout/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const DropdownButton = () => import('../../components/layout/DropdownButton.vue' /* webpackChunkName: "components/dropdown-button" */).then(c => wrapFunctional(c.default || c))
export const DropdownFocus = () => import('../../components/layout/DropdownFocus.vue' /* webpackChunkName: "components/dropdown-focus" */).then(c => wrapFunctional(c.default || c))
export const DropdownItem = () => import('../../components/layout/DropdownItem.vue' /* webpackChunkName: "components/dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const EmailDropdownInput = () => import('../../components/layout/EmailDropdownInput.vue' /* webpackChunkName: "components/email-dropdown-input" */).then(c => wrapFunctional(c.default || c))
export const HoldbackStatusBadge = () => import('../../components/layout/HoldbackStatusBadge.vue' /* webpackChunkName: "components/holdback-status-badge" */).then(c => wrapFunctional(c.default || c))
export const LegalStatusBadge = () => import('../../components/layout/LegalStatusBadge.vue' /* webpackChunkName: "components/legal-status-badge" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/layout/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/layout/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const MobileSidebar = () => import('../../components/layout/MobileSidebar.vue' /* webpackChunkName: "components/mobile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MobileSidebarToggle = () => import('../../components/layout/MobileSidebarToggle.vue' /* webpackChunkName: "components/mobile-sidebar-toggle" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/layout/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalCloseButton = () => import('../../components/layout/ModalCloseButton.vue' /* webpackChunkName: "components/modal-close-button" */).then(c => wrapFunctional(c.default || c))
export const RadioButtons = () => import('../../components/layout/RadioButtons.vue' /* webpackChunkName: "components/radio-buttons" */).then(c => wrapFunctional(c.default || c))
export const RadioButtonsRound = () => import('../../components/layout/RadioButtonsRound.vue' /* webpackChunkName: "components/radio-buttons-round" */).then(c => wrapFunctional(c.default || c))
export const ResizeableTextarea = () => import('../../components/layout/ResizeableTextarea.vue' /* webpackChunkName: "components/resizeable-textarea" */).then(c => wrapFunctional(c.default || c))
export const SearchDropdown = () => import('../../components/layout/SearchDropdown.vue' /* webpackChunkName: "components/search-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/layout/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const SidePanel = () => import('../../components/layout/SidePanel.vue' /* webpackChunkName: "components/side-panel" */).then(c => wrapFunctional(c.default || c))
export const SidePanelCloseButton = () => import('../../components/layout/SidePanelCloseButton.vue' /* webpackChunkName: "components/side-panel-close-button" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/layout/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SidebarItem = () => import('../../components/layout/SidebarItem.vue' /* webpackChunkName: "components/sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const SidebarTitle = () => import('../../components/layout/SidebarTitle.vue' /* webpackChunkName: "components/sidebar-title" */).then(c => wrapFunctional(c.default || c))
export const TaxableFieldInfo = () => import('../../components/layout/TaxableFieldInfo.vue' /* webpackChunkName: "components/taxable-field-info" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../components/layout/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const V3DatePicker = () => import('../../components/v3datepicker/V3DatePicker.vue' /* webpackChunkName: "components/v3-date-picker" */).then(c => wrapFunctional(c.default || c))
export const V3DatePickerCalendar = () => import('../../components/v3datepicker/V3DatePickerCalendar.vue' /* webpackChunkName: "components/v3-date-picker-calendar" */).then(c => wrapFunctional(c.default || c))
export const V3DatePickerContainer = () => import('../../components/v3datepicker/V3DatePickerContainer.vue' /* webpackChunkName: "components/v3-date-picker-container" */).then(c => wrapFunctional(c.default || c))
export const V3DatePickerStandalone = () => import('../../components/v3datepicker/V3DatePickerStandalone.vue' /* webpackChunkName: "components/v3-date-picker-standalone" */).then(c => wrapFunctional(c.default || c))
export const AccountAddressField = () => import('../../components/account/AccountAddressField.vue' /* webpackChunkName: "components/account-address-field" */).then(c => wrapFunctional(c.default || c))
export const AccountApartCountry = () => import('../../components/account/AccountApartCountry.vue' /* webpackChunkName: "components/account-apart-country" */).then(c => wrapFunctional(c.default || c))
export const AccountClientNumbering = () => import('../../components/account/AccountClientNumbering.vue' /* webpackChunkName: "components/account-client-numbering" */).then(c => wrapFunctional(c.default || c))
export const AccountContactNumbersForm = () => import('../../components/account/AccountContactNumbersForm.vue' /* webpackChunkName: "components/account-contact-numbers-form" */).then(c => wrapFunctional(c.default || c))
export const AccountContactNumbersModal = () => import('../../components/account/AccountContactNumbersModal.vue' /* webpackChunkName: "components/account-contact-numbers-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountCreateCompanyModal = () => import('../../components/account/AccountCreateCompanyModal.vue' /* webpackChunkName: "components/account-create-company-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountCreditNumbering = () => import('../../components/account/AccountCreditNumbering.vue' /* webpackChunkName: "components/account-credit-numbering" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultWorksDelay = () => import('../../components/account/AccountDefaultWorksDelay.vue' /* webpackChunkName: "components/account-default-works-delay" */).then(c => wrapFunctional(c.default || c))
export const AccountDefaultWorksDuration = () => import('../../components/account/AccountDefaultWorksDuration.vue' /* webpackChunkName: "components/account-default-works-duration" */).then(c => wrapFunctional(c.default || c))
export const AccountDocumentFooterPreview = () => import('../../components/account/AccountDocumentFooterPreview.vue' /* webpackChunkName: "components/account-document-footer-preview" */).then(c => wrapFunctional(c.default || c))
export const AccountInvoiceNumbering = () => import('../../components/account/AccountInvoiceNumbering.vue' /* webpackChunkName: "components/account-invoice-numbering" */).then(c => wrapFunctional(c.default || c))
export const AccountNumbersForm = () => import('../../components/account/AccountNumbersForm.vue' /* webpackChunkName: "components/account-numbers-form" */).then(c => wrapFunctional(c.default || c))
export const AccountNumbersModal = () => import('../../components/account/AccountNumbersModal.vue' /* webpackChunkName: "components/account-numbers-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountOrderFormNumbering = () => import('../../components/account/AccountOrderFormNumbering.vue' /* webpackChunkName: "components/account-order-form-numbering" */).then(c => wrapFunctional(c.default || c))
export const AccountQuoteNumbering = () => import('../../components/account/AccountQuoteNumbering.vue' /* webpackChunkName: "components/account-quote-numbering" */).then(c => wrapFunctional(c.default || c))
export const AccountSettingsForm = () => import('../../components/account/AccountSettingsForm.vue' /* webpackChunkName: "components/account-settings-form" */).then(c => wrapFunctional(c.default || c))
export const AccountSupplierNumbering = () => import('../../components/account/AccountSupplierNumbering.vue' /* webpackChunkName: "components/account-supplier-numbering" */).then(c => wrapFunctional(c.default || c))
export const AccountTermsForm = () => import('../../components/account/AccountTermsForm.vue' /* webpackChunkName: "components/account-terms-form" */).then(c => wrapFunctional(c.default || c))
export const AccountUpdateUserModal = () => import('../../components/account/AccountUpdateUserModal.vue' /* webpackChunkName: "components/account-update-user-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountUserDeleteModal = () => import('../../components/account/AccountUserDeleteModal.vue' /* webpackChunkName: "components/account-user-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountUserModal = () => import('../../components/account/AccountUserModal.vue' /* webpackChunkName: "components/account-user-modal" */).then(c => wrapFunctional(c.default || c))
export const InvoiceCreateModal = () => import('../../components/invoice/InvoiceCreateModal.vue' /* webpackChunkName: "components/invoice-create-modal" */).then(c => wrapFunctional(c.default || c))
export const InvoiceCreateModalContacts = () => import('../../components/invoice/InvoiceCreateModalContacts.vue' /* webpackChunkName: "components/invoice-create-modal-contacts" */).then(c => wrapFunctional(c.default || c))
export const InvoiceCreateModalQuotes = () => import('../../components/invoice/InvoiceCreateModalQuotes.vue' /* webpackChunkName: "components/invoice-create-modal-quotes" */).then(c => wrapFunctional(c.default || c))
export const InvoiceCreateModalResultContact = () => import('../../components/invoice/InvoiceCreateModalResultContact.vue' /* webpackChunkName: "components/invoice-create-modal-result-contact" */).then(c => wrapFunctional(c.default || c))
export const InvoiceCreateModalResultQuote = () => import('../../components/invoice/InvoiceCreateModalResultQuote.vue' /* webpackChunkName: "components/invoice-create-modal-result-quote" */).then(c => wrapFunctional(c.default || c))
export const InvoiceExportModal = () => import('../../components/invoice/InvoiceExportModal.vue' /* webpackChunkName: "components/invoice-export-modal" */).then(c => wrapFunctional(c.default || c))
export const Planning = () => import('../../components/planning/Planning.vue' /* webpackChunkName: "components/planning" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttBetaModal = () => import('../../components/planning/PlanningGanttBetaModal.vue' /* webpackChunkName: "components/planning-gantt-beta-modal" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttChart = () => import('../../components/planning/PlanningGanttChart.vue' /* webpackChunkName: "components/planning-gantt-chart" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttChartHeader = () => import('../../components/planning/PlanningGanttChartHeader.vue' /* webpackChunkName: "components/planning-gantt-chart-header" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttTaskHeader = () => import('../../components/planning/PlanningGanttTaskHeader.vue' /* webpackChunkName: "components/planning-gantt-task-header" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttTaskHeaderActions = () => import('../../components/planning/PlanningGanttTaskHeaderActions.vue' /* webpackChunkName: "components/planning-gantt-task-header-actions" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttTaskHeaderBlank = () => import('../../components/planning/PlanningGanttTaskHeaderBlank.vue' /* webpackChunkName: "components/planning-gantt-task-header-blank" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttTaskHeaderName = () => import('../../components/planning/PlanningGanttTaskHeaderName.vue' /* webpackChunkName: "components/planning-gantt-task-header-name" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttTaskRow = () => import('../../components/planning/PlanningGanttTaskRow.vue' /* webpackChunkName: "components/planning-gantt-task-row" */).then(c => wrapFunctional(c.default || c))
export const PlanningGanttTaskRowBlank = () => import('../../components/planning/PlanningGanttTaskRowBlank.vue' /* webpackChunkName: "components/planning-gantt-task-row-blank" */).then(c => wrapFunctional(c.default || c))
export const PlanningPrintModal = () => import('../../components/planning/PlanningPrintModal.vue' /* webpackChunkName: "components/planning-print-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryCategory = () => import('../../components/library/LibraryCategory.vue' /* webpackChunkName: "components/library-category" */).then(c => wrapFunctional(c.default || c))
export const LibraryCategoryLink = () => import('../../components/library/LibraryCategoryLink.vue' /* webpackChunkName: "components/library-category-link" */).then(c => wrapFunctional(c.default || c))
export const LibraryContainer = () => import('../../components/library/LibraryContainer.vue' /* webpackChunkName: "components/library-container" */).then(c => wrapFunctional(c.default || c))
export const LibraryDocumentLinesModal = () => import('../../components/library/LibraryDocumentLinesModal.vue' /* webpackChunkName: "components/library-document-lines-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryDocumentsContainer = () => import('../../components/library/LibraryDocumentsContainer.vue' /* webpackChunkName: "components/library-documents-container" */).then(c => wrapFunctional(c.default || c))
export const OnboardingModal = () => import('../../components/onboarding/OnboardingModal.vue' /* webpackChunkName: "components/onboarding-modal" */).then(c => wrapFunctional(c.default || c))
export const OnboardingDocumentNumbering = () => import('../../components/onboarding/documents/OnboardingDocumentNumbering.vue' /* webpackChunkName: "components/onboarding-document-numbering" */).then(c => wrapFunctional(c.default || c))
export const OnboardingGlobalStep1Back = () => import('../../components/onboarding/global/OnboardingGlobalStep1-back.vue' /* webpackChunkName: "components/onboarding-global-step1-back" */).then(c => wrapFunctional(c.default || c))
export const OnboardingGlobalStep1 = () => import('../../components/onboarding/global/OnboardingGlobalStep1.vue' /* webpackChunkName: "components/onboarding-global-step1" */).then(c => wrapFunctional(c.default || c))
export const OnboardingGlobalStep2 = () => import('../../components/onboarding/global/OnboardingGlobalStep2.vue' /* webpackChunkName: "components/onboarding-global-step2" */).then(c => wrapFunctional(c.default || c))
export const OnboardingGlobalStep3 = () => import('../../components/onboarding/global/OnboardingGlobalStep3.vue' /* webpackChunkName: "components/onboarding-global-step3" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvents = () => import('../../components/dashboard/DashboardEvents.vue' /* webpackChunkName: "components/dashboard-events" */).then(c => wrapFunctional(c.default || c))
export const DashboardGrossVolume = () => import('../../components/dashboard/DashboardGrossVolume.vue' /* webpackChunkName: "components/dashboard-gross-volume" */).then(c => wrapFunctional(c.default || c))
export const DashboardInvoices = () => import('../../components/dashboard/DashboardInvoices.vue' /* webpackChunkName: "components/dashboard-invoices" */).then(c => wrapFunctional(c.default || c))
export const DashboardMobileGrossVolume = () => import('../../components/dashboard/DashboardMobileGrossVolume.vue' /* webpackChunkName: "components/dashboard-mobile-gross-volume" */).then(c => wrapFunctional(c.default || c))
export const DashboardMobileInvoices = () => import('../../components/dashboard/DashboardMobileInvoices.vue' /* webpackChunkName: "components/dashboard-mobile-invoices" */).then(c => wrapFunctional(c.default || c))
export const DashboardMobileShortcuts = () => import('../../components/dashboard/DashboardMobileShortcuts.vue' /* webpackChunkName: "components/dashboard-mobile-shortcuts" */).then(c => wrapFunctional(c.default || c))
export const DashboardNotes = () => import('../../components/dashboard/DashboardNotes.vue' /* webpackChunkName: "components/dashboard-notes" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjects = () => import('../../components/dashboard/DashboardProjects.vue' /* webpackChunkName: "components/dashboard-projects" */).then(c => wrapFunctional(c.default || c))
export const DashboardQuotes = () => import('../../components/dashboard/DashboardQuotes.vue' /* webpackChunkName: "components/dashboard-quotes" */).then(c => wrapFunctional(c.default || c))
export const DashboardTax = () => import('../../components/dashboard/DashboardTax.vue' /* webpackChunkName: "components/dashboard-tax" */).then(c => wrapFunctional(c.default || c))
export const DashboardTaxMobile = () => import('../../components/dashboard/DashboardTaxMobile.vue' /* webpackChunkName: "components/dashboard-tax-mobile" */).then(c => wrapFunctional(c.default || c))
export const DashboardTotalGrossVolume = () => import('../../components/dashboard/DashboardTotalGrossVolume.vue' /* webpackChunkName: "components/dashboard-total-gross-volume" */).then(c => wrapFunctional(c.default || c))
export const ListCompanyUsers = () => import('../../components/lists/ListCompanyUsers.vue' /* webpackChunkName: "components/list-company-users" */).then(c => wrapFunctional(c.default || c))
export const ListCompanyUsersActions = () => import('../../components/lists/ListCompanyUsersActions.vue' /* webpackChunkName: "components/list-company-users-actions" */).then(c => wrapFunctional(c.default || c))
export const ListConfigModal = () => import('../../components/lists/ListConfigModal.vue' /* webpackChunkName: "components/list-config-modal" */).then(c => wrapFunctional(c.default || c))
export const ListContacts = () => import('../../components/lists/ListContacts.vue' /* webpackChunkName: "components/list-contacts" */).then(c => wrapFunctional(c.default || c))
export const ListContactsActions = () => import('../../components/lists/ListContactsActions.vue' /* webpackChunkName: "components/list-contacts-actions" */).then(c => wrapFunctional(c.default || c))
export const ListContactsEmptyState = () => import('../../components/lists/ListContactsEmptyState.vue' /* webpackChunkName: "components/list-contacts-empty-state" */).then(c => wrapFunctional(c.default || c))
export const ListCredits = () => import('../../components/lists/ListCredits.vue' /* webpackChunkName: "components/list-credits" */).then(c => wrapFunctional(c.default || c))
export const ListCreditsEmptyState = () => import('../../components/lists/ListCreditsEmptyState.vue' /* webpackChunkName: "components/list-credits-empty-state" */).then(c => wrapFunctional(c.default || c))
export const ListDocumentsActions = () => import('../../components/lists/ListDocumentsActions.vue' /* webpackChunkName: "components/list-documents-actions" */).then(c => wrapFunctional(c.default || c))
export const ListDocumentsActionsMobile = () => import('../../components/lists/ListDocumentsActionsMobile.vue' /* webpackChunkName: "components/list-documents-actions-mobile" */).then(c => wrapFunctional(c.default || c))
export const ListHoldbacks = () => import('../../components/lists/ListHoldbacks.vue' /* webpackChunkName: "components/list-holdbacks" */).then(c => wrapFunctional(c.default || c))
export const ListHoldbacksActions = () => import('../../components/lists/ListHoldbacksActions.vue' /* webpackChunkName: "components/list-holdbacks-actions" */).then(c => wrapFunctional(c.default || c))
export const ListIncomes = () => import('../../components/lists/ListIncomes.vue' /* webpackChunkName: "components/list-incomes" */).then(c => wrapFunctional(c.default || c))
export const ListInvoicePaymentChart = () => import('../../components/lists/ListInvoicePaymentChart.vue' /* webpackChunkName: "components/list-invoice-payment-chart" */).then(c => wrapFunctional(c.default || c))
export const ListInvoices = () => import('../../components/lists/ListInvoices.vue' /* webpackChunkName: "components/list-invoices" */).then(c => wrapFunctional(c.default || c))
export const ListInvoicesSimpleLine = () => import('../../components/lists/ListInvoicesSimpleLine.vue' /* webpackChunkName: "components/list-invoices-simple-line" */).then(c => wrapFunctional(c.default || c))
export const ListOrderForms = () => import('../../components/lists/ListOrderForms.vue' /* webpackChunkName: "components/list-order-forms" */).then(c => wrapFunctional(c.default || c))
export const ListPagination = () => import('../../components/lists/ListPagination.vue' /* webpackChunkName: "components/list-pagination" */).then(c => wrapFunctional(c.default || c))
export const ListPayments = () => import('../../components/lists/ListPayments.vue' /* webpackChunkName: "components/list-payments" */).then(c => wrapFunctional(c.default || c))
export const ListProducts = () => import('../../components/lists/ListProducts.vue' /* webpackChunkName: "components/list-products" */).then(c => wrapFunctional(c.default || c))
export const ListProductsActions = () => import('../../components/lists/ListProductsActions.vue' /* webpackChunkName: "components/list-products-actions" */).then(c => wrapFunctional(c.default || c))
export const ListProductsActionsDropdown = () => import('../../components/lists/ListProductsActionsDropdown.vue' /* webpackChunkName: "components/list-products-actions-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ListProductsEmptyState = () => import('../../components/lists/ListProductsEmptyState.vue' /* webpackChunkName: "components/list-products-empty-state" */).then(c => wrapFunctional(c.default || c))
export const ListProjects = () => import('../../components/lists/ListProjects.vue' /* webpackChunkName: "components/list-projects" */).then(c => wrapFunctional(c.default || c))
export const ListProjectsColumn = () => import('../../components/lists/ListProjectsColumn.vue' /* webpackChunkName: "components/list-projects-column" */).then(c => wrapFunctional(c.default || c))
export const ListProjectsEmptyState = () => import('../../components/lists/ListProjectsEmptyState.vue' /* webpackChunkName: "components/list-projects-empty-state" */).then(c => wrapFunctional(c.default || c))
export const ListQuotePaymentChart = () => import('../../components/lists/ListQuotePaymentChart.vue' /* webpackChunkName: "components/list-quote-payment-chart" */).then(c => wrapFunctional(c.default || c))
export const ListQuotes = () => import('../../components/lists/ListQuotes.vue' /* webpackChunkName: "components/list-quotes" */).then(c => wrapFunctional(c.default || c))
export const ListQuotesDashboardLine = () => import('../../components/lists/ListQuotesDashboardLine.vue' /* webpackChunkName: "components/list-quotes-dashboard-line" */).then(c => wrapFunctional(c.default || c))
export const ListQuotesSimpleLine = () => import('../../components/lists/ListQuotesSimpleLine.vue' /* webpackChunkName: "components/list-quotes-simple-line" */).then(c => wrapFunctional(c.default || c))
export const ListSubscriptionInvoices = () => import('../../components/lists/ListSubscriptionInvoices.vue' /* webpackChunkName: "components/list-subscription-invoices" */).then(c => wrapFunctional(c.default || c))
export const ListSupplierInvoices = () => import('../../components/lists/ListSupplierInvoices.vue' /* webpackChunkName: "components/list-supplier-invoices" */).then(c => wrapFunctional(c.default || c))
export const ListSupplierInvoicesActions = () => import('../../components/lists/ListSupplierInvoicesActions.vue' /* webpackChunkName: "components/list-supplier-invoices-actions" */).then(c => wrapFunctional(c.default || c))
export const ListSuppliers = () => import('../../components/lists/ListSuppliers.vue' /* webpackChunkName: "components/list-suppliers" */).then(c => wrapFunctional(c.default || c))
export const ListTable = () => import('../../components/lists/ListTable.vue' /* webpackChunkName: "components/list-table" */).then(c => wrapFunctional(c.default || c))
export const ListTasksActions = () => import('../../components/lists/ListTasksActions.vue' /* webpackChunkName: "components/list-tasks-actions" */).then(c => wrapFunctional(c.default || c))
export const ListTransactions = () => import('../../components/lists/ListTransactions.vue' /* webpackChunkName: "components/list-transactions" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearch = () => import('../../components/search/GlobalSearch.vue' /* webpackChunkName: "components/global-search" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchContact = () => import('../../components/search/GlobalSearchContact.vue' /* webpackChunkName: "components/global-search-contact" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchDocument = () => import('../../components/search/GlobalSearchDocument.vue' /* webpackChunkName: "components/global-search-document" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchProduct = () => import('../../components/search/GlobalSearchProduct.vue' /* webpackChunkName: "components/global-search-product" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchProject = () => import('../../components/search/GlobalSearchProject.vue' /* webpackChunkName: "components/global-search-project" */).then(c => wrapFunctional(c.default || c))
export const AssetPreviewModal = () => import('../../components/document/AssetPreviewModal.vue' /* webpackChunkName: "components/asset-preview-modal" */).then(c => wrapFunctional(c.default || c))
export const CancelInvoiceModal = () => import('../../components/document/CancelInvoiceModal.vue' /* webpackChunkName: "components/cancel-invoice-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfirmChargeSituationBeforeDownPaymentsModal = () => import('../../components/document/ConfirmChargeSituationBeforeDownPaymentsModal.vue' /* webpackChunkName: "components/confirm-charge-situation-before-down-payments-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateOrderModal = () => import('../../components/document/CreateOrderModal.vue' /* webpackChunkName: "components/create-order-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateStandaloneVatAttestationModal = () => import('../../components/document/CreateStandaloneVatAttestationModal.vue' /* webpackChunkName: "components/create-standalone-vat-attestation-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateVatAttestationModal = () => import('../../components/document/CreateVatAttestationModal.vue' /* webpackChunkName: "components/create-vat-attestation-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentAddPaymentModal = () => import('../../components/document/DocumentAddPaymentModal.vue' /* webpackChunkName: "components/document-add-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentArchiveLine = () => import('../../components/document/DocumentArchiveLine.vue' /* webpackChunkName: "components/document-archive-line" */).then(c => wrapFunctional(c.default || c))
export const DocumentDetailsSendDropdown = () => import('../../components/document/DocumentDetailsSendDropdown.vue' /* webpackChunkName: "components/document-details-send-dropdown" */).then(c => wrapFunctional(c.default || c))
export const DocumentEditReasonModal = () => import('../../components/document/DocumentEditReasonModal.vue' /* webpackChunkName: "components/document-edit-reason-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentEmailPreviewModal = () => import('../../components/document/DocumentEmailPreviewModal.vue' /* webpackChunkName: "components/document-email-preview-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentEmailsModal = () => import('../../components/document/DocumentEmailsModal.vue' /* webpackChunkName: "components/document-emails-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentImportModal = () => import('../../components/document/DocumentImportModal.vue' /* webpackChunkName: "components/document-import-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentLabelsCreateModal = () => import('../../components/document/DocumentLabelsCreateModal.vue' /* webpackChunkName: "components/document-labels-create-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentLabelsModal = () => import('../../components/document/DocumentLabelsModal.vue' /* webpackChunkName: "components/document-labels-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentMarkAsPaidModal = () => import('../../components/document/DocumentMarkAsPaidModal.vue' /* webpackChunkName: "components/document-mark-as-paid-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentMarkAsSentModal = () => import('../../components/document/DocumentMarkAsSentModal.vue' /* webpackChunkName: "components/document-mark-as-sent-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentNotes = () => import('../../components/document/DocumentNotes.vue' /* webpackChunkName: "components/document-notes" */).then(c => wrapFunctional(c.default || c))
export const DocumentNumberModal = () => import('../../components/document/DocumentNumberModal.vue' /* webpackChunkName: "components/document-number-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentPdf = () => import('../../components/document/DocumentPdf.vue' /* webpackChunkName: "components/document-pdf" */).then(c => wrapFunctional(c.default || c))
export const DocumentRevisionsModal = () => import('../../components/document/DocumentRevisionsModal.vue' /* webpackChunkName: "components/document-revisions-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentShare = () => import('../../components/document/DocumentShare.vue' /* webpackChunkName: "components/document-share" */).then(c => wrapFunctional(c.default || c))
export const DocumentShareModal = () => import('../../components/document/DocumentShareModal.vue' /* webpackChunkName: "components/document-share-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentTemplatingForm = () => import('../../components/document/DocumentTemplatingForm.vue' /* webpackChunkName: "components/document-templating-form" */).then(c => wrapFunctional(c.default || c))
export const DocumentTemplatingTableOption = () => import('../../components/document/DocumentTemplatingTableOption.vue' /* webpackChunkName: "components/document-templating-table-option" */).then(c => wrapFunctional(c.default || c))
export const EventListItem = () => import('../../components/document/EventListItem.vue' /* webpackChunkName: "components/event-list-item" */).then(c => wrapFunctional(c.default || c))
export const InvoiceSubTypeBadge = () => import('../../components/document/InvoiceSubTypeBadge.vue' /* webpackChunkName: "components/invoice-sub-type-badge" */).then(c => wrapFunctional(c.default || c))
export const PaybylinkModal = () => import('../../components/document/PaybylinkModal.vue' /* webpackChunkName: "components/paybylink-modal" */).then(c => wrapFunctional(c.default || c))
export const SendDocumentContainer = () => import('../../components/document/SendDocumentContainer.vue' /* webpackChunkName: "components/send-document-container" */).then(c => wrapFunctional(c.default || c))
export const SendDocumentDropdown = () => import('../../components/document/SendDocumentDropdown.vue' /* webpackChunkName: "components/send-document-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SendDocumentModal = () => import('../../components/document/SendDocumentModal.vue' /* webpackChunkName: "components/send-document-modal" */).then(c => wrapFunctional(c.default || c))
export const SlipPrintModal = () => import('../../components/document/SlipPrintModal.vue' /* webpackChunkName: "components/slip-print-modal" */).then(c => wrapFunctional(c.default || c))
export const StatusBadge = () => import('../../components/document/StatusBadge.vue' /* webpackChunkName: "components/status-badge" */).then(c => wrapFunctional(c.default || c))
export const StatusCardSide = () => import('../../components/document/StatusCardSide.vue' /* webpackChunkName: "components/status-card-side" */).then(c => wrapFunctional(c.default || c))
export const EmailContentPlaceholder = () => import('../../components/email/EmailContentPlaceholder.js' /* webpackChunkName: "components/email-content-placeholder" */).then(c => wrapFunctional(c.default || c))
export const EmailContentPlaceholderComponent = () => import('../../components/email/EmailContentPlaceholderComponent.vue' /* webpackChunkName: "components/email-content-placeholder-component" */).then(c => wrapFunctional(c.default || c))
export const EmailEditor = () => import('../../components/email/EmailEditor.vue' /* webpackChunkName: "components/email-editor" */).then(c => wrapFunctional(c.default || c))
export const EmailEditorBlurredContent = () => import('../../components/email/EmailEditorBlurredContent.vue' /* webpackChunkName: "components/email-editor-blurred-content" */).then(c => wrapFunctional(c.default || c))
export const EmailEditorPlaceholdersDropdown = () => import('../../components/email/EmailEditorPlaceholdersDropdown.vue' /* webpackChunkName: "components/email-editor-placeholders-dropdown" */).then(c => wrapFunctional(c.default || c))
export const EmailTemplate = () => import('../../components/email/EmailTemplate.vue' /* webpackChunkName: "components/email-template" */).then(c => wrapFunctional(c.default || c))
export const EmailTemplateCreditBottom = () => import('../../components/email/EmailTemplateCreditBottom.vue' /* webpackChunkName: "components/email-template-credit-bottom" */).then(c => wrapFunctional(c.default || c))
export const EmailTemplateInvoiceBottom = () => import('../../components/email/EmailTemplateInvoiceBottom.vue' /* webpackChunkName: "components/email-template-invoice-bottom" */).then(c => wrapFunctional(c.default || c))
export const EmailTemplateModal = () => import('../../components/email/EmailTemplateModal.vue' /* webpackChunkName: "components/email-template-modal" */).then(c => wrapFunctional(c.default || c))
export const EmailTemplateOrderFormBottom = () => import('../../components/email/EmailTemplateOrderFormBottom.vue' /* webpackChunkName: "components/email-template-order-form-bottom" */).then(c => wrapFunctional(c.default || c))
export const EmailTemplateQuoteBottom = () => import('../../components/email/EmailTemplateQuoteBottom.vue' /* webpackChunkName: "components/email-template-quote-bottom" */).then(c => wrapFunctional(c.default || c))
export const EmailTemplateSignature = () => import('../../components/email/EmailTemplateSignature.vue' /* webpackChunkName: "components/email-template-signature" */).then(c => wrapFunctional(c.default || c))
export const ProjectBadge = () => import('../../components/project/ProjectBadge.vue' /* webpackChunkName: "components/project-badge" */).then(c => wrapFunctional(c.default || c))
export const ProjectBudgetChart = () => import('../../components/project/ProjectBudgetChart.vue' /* webpackChunkName: "components/project-budget-chart" */).then(c => wrapFunctional(c.default || c))
export const ProjectCard = () => import('../../components/project/ProjectCard.vue' /* webpackChunkName: "components/project-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectCategoryModal = () => import('../../components/project/ProjectCategoryModal.vue' /* webpackChunkName: "components/project-category-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectCertificateCompleteModal = () => import('../../components/project/ProjectCertificateCompleteModal.vue' /* webpackChunkName: "components/project-certificate-complete-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectCertificateCompletion = () => import('../../components/project/ProjectCertificateCompletion.vue' /* webpackChunkName: "components/project-certificate-completion" */).then(c => wrapFunctional(c.default || c))
export const ProjectCertificateModal = () => import('../../components/project/ProjectCertificateModal.vue' /* webpackChunkName: "components/project-certificate-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectCertificatePtu = () => import('../../components/project/ProjectCertificatePtu.vue' /* webpackChunkName: "components/project-certificate-ptu" */).then(c => wrapFunctional(c.default || c))
export const ProjectCertificateReservesModal = () => import('../../components/project/ProjectCertificateReservesModal.vue' /* webpackChunkName: "components/project-certificate-reserves-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectCostsChart = () => import('../../components/project/ProjectCostsChart.vue' /* webpackChunkName: "components/project-costs-chart" */).then(c => wrapFunctional(c.default || c))
export const ProjectCreateModal = () => import('../../components/project/ProjectCreateModal.vue' /* webpackChunkName: "components/project-create-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsAttachedFiles = () => import('../../components/project/ProjectDetailsAttachedFiles.vue' /* webpackChunkName: "components/project-details-attached-files" */).then(c => wrapFunctional(c.default || c))
export const ProjectHoursModal = () => import('../../components/project/ProjectHoursModal.vue' /* webpackChunkName: "components/project-hours-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectProfit = () => import('../../components/project/ProjectProfit.vue' /* webpackChunkName: "components/project-profit" */).then(c => wrapFunctional(c.default || c))
export const ProjectProfitability = () => import('../../components/project/ProjectProfitability.vue' /* webpackChunkName: "components/project-profitability" */).then(c => wrapFunctional(c.default || c))
export const ProjectProfitabilityModal = () => import('../../components/project/ProjectProfitabilityModal.vue' /* webpackChunkName: "components/project-profitability-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectReportReserveRow = () => import('../../components/project/ProjectReportReserveRow.vue' /* webpackChunkName: "components/project-report-reserve-row" */).then(c => wrapFunctional(c.default || c))
export const ProjectSummary = () => import('../../components/project/ProjectSummary.vue' /* webpackChunkName: "components/project-summary" */).then(c => wrapFunctional(c.default || c))
export const ProjectTaskForm = () => import('../../components/project/ProjectTaskForm.vue' /* webpackChunkName: "components/project-task-form" */).then(c => wrapFunctional(c.default || c))
export const ProjectTaskFormHoursSelect = () => import('../../components/project/ProjectTaskFormHoursSelect.vue' /* webpackChunkName: "components/project-task-form-hours-select" */).then(c => wrapFunctional(c.default || c))
export const ProjectTaskModal = () => import('../../components/project/ProjectTaskModal.vue' /* webpackChunkName: "components/project-task-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectsArchivesSidePanel = () => import('../../components/project/ProjectsArchivesSidePanel.vue' /* webpackChunkName: "components/projects-archives-side-panel" */).then(c => wrapFunctional(c.default || c))
export const ContactBadge = () => import('../../components/contact/ContactBadge.vue' /* webpackChunkName: "components/contact-badge" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../components/contact/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContactCardFull = () => import('../../components/contact/ContactCardFull.vue' /* webpackChunkName: "components/contact-card-full" */).then(c => wrapFunctional(c.default || c))
export const ContactCredits = () => import('../../components/contact/ContactCredits.vue' /* webpackChunkName: "components/contact-credits" */).then(c => wrapFunctional(c.default || c))
export const ContactExportModal = () => import('../../components/contact/ContactExportModal.vue' /* webpackChunkName: "components/contact-export-modal" */).then(c => wrapFunctional(c.default || c))
export const ContactInvoices = () => import('../../components/contact/ContactInvoices.vue' /* webpackChunkName: "components/contact-invoices" */).then(c => wrapFunctional(c.default || c))
export const ContactProjects = () => import('../../components/contact/ContactProjects.vue' /* webpackChunkName: "components/contact-projects" */).then(c => wrapFunctional(c.default || c))
export const ContactQuotes = () => import('../../components/contact/ContactQuotes.vue' /* webpackChunkName: "components/contact-quotes" */).then(c => wrapFunctional(c.default || c))
export const ContactSidePanel = () => import('../../components/contact/ContactSidePanel.vue' /* webpackChunkName: "components/contact-side-panel" */).then(c => wrapFunctional(c.default || c))
export const ContactSummary = () => import('../../components/contact/ContactSummary.vue' /* webpackChunkName: "components/contact-summary" */).then(c => wrapFunctional(c.default || c))
export const ContactSupplierInvoices = () => import('../../components/contact/ContactSupplierInvoices.vue' /* webpackChunkName: "components/contact-supplier-invoices" */).then(c => wrapFunctional(c.default || c))
export const CreateContactModal = () => import('../../components/contact/CreateContactModal.vue' /* webpackChunkName: "components/create-contact-modal" */).then(c => wrapFunctional(c.default || c))
export const SupplierSummary = () => import('../../components/contact/SupplierSummary.vue' /* webpackChunkName: "components/supplier-summary" */).then(c => wrapFunctional(c.default || c))
export const TextEditor = () => import('../../components/TextEditor/TextEditor.vue' /* webpackChunkName: "components/text-editor" */).then(c => wrapFunctional(c.default || c))
export const TextEditorToolbar = () => import('../../components/TextEditor/TextEditorToolbar.vue' /* webpackChunkName: "components/text-editor-toolbar" */).then(c => wrapFunctional(c.default || c))
export const TextEditorToolbarBubble = () => import('../../components/TextEditor/TextEditorToolbarBubble.vue' /* webpackChunkName: "components/text-editor-toolbar-bubble" */).then(c => wrapFunctional(c.default || c))
export const TextEditorToolbarColorButton = () => import('../../components/TextEditor/TextEditorToolbarColorButton.vue' /* webpackChunkName: "components/text-editor-toolbar-color-button" */).then(c => wrapFunctional(c.default || c))
export const TextEditorToolbarFontSizeButton = () => import('../../components/TextEditor/TextEditorToolbarFontSizeButton.vue' /* webpackChunkName: "components/text-editor-toolbar-font-size-button" */).then(c => wrapFunctional(c.default || c))
export const TextEditorToolbarImageButton = () => import('../../components/TextEditor/TextEditorToolbarImageButton.vue' /* webpackChunkName: "components/text-editor-toolbar-image-button" */).then(c => wrapFunctional(c.default || c))
export const TextEditorToolbarLinkBubble = () => import('../../components/TextEditor/TextEditorToolbarLinkBubble.vue' /* webpackChunkName: "components/text-editor-toolbar-link-bubble" */).then(c => wrapFunctional(c.default || c))
export const TextEditorToolbarLinkButton = () => import('../../components/TextEditor/TextEditorToolbarLinkButton.vue' /* webpackChunkName: "components/text-editor-toolbar-link-button" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../components/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const AddressFieldset = () => import('../../components/AddressFieldset.vue' /* webpackChunkName: "components/address-fieldset" */).then(c => wrapFunctional(c.default || c))
export const AmountCard = () => import('../../components/AmountCard.vue' /* webpackChunkName: "components/amount-card" */).then(c => wrapFunctional(c.default || c))
export const AttachmentFileIcon = () => import('../../components/AttachmentFileIcon.vue' /* webpackChunkName: "components/attachment-file-icon" */).then(c => wrapFunctional(c.default || c))
export const AttachmentFileRow = () => import('../../components/AttachmentFileRow.vue' /* webpackChunkName: "components/attachment-file-row" */).then(c => wrapFunctional(c.default || c))
export const AttachmentRow = () => import('../../components/AttachmentRow.vue' /* webpackChunkName: "components/attachment-row" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const BankSyncModal = () => import('../../components/BankSyncModal.vue' /* webpackChunkName: "components/bank-sync-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseLayout = () => import('../../components/BaseLayout.vue' /* webpackChunkName: "components/base-layout" */).then(c => wrapFunctional(c.default || c))
export const BetaBadge = () => import('../../components/BetaBadge.vue' /* webpackChunkName: "components/beta-badge" */).then(c => wrapFunctional(c.default || c))
export const CanceledLockModal = () => import('../../components/CanceledLockModal.vue' /* webpackChunkName: "components/canceled-lock-modal" */).then(c => wrapFunctional(c.default || c))
export const CompanyOnboardingTasks = () => import('../../components/CompanyOnboardingTasks.vue' /* webpackChunkName: "components/company-onboarding-tasks" */).then(c => wrapFunctional(c.default || c))
export const CompanySignature = () => import('../../components/CompanySignature.vue' /* webpackChunkName: "components/company-signature" */).then(c => wrapFunctional(c.default || c))
export const CreateDropdown = () => import('../../components/CreateDropdown.vue' /* webpackChunkName: "components/create-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CreateProductModal = () => import('../../components/CreateProductModal.vue' /* webpackChunkName: "components/create-product-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateSupplierExpenseModal = () => import('../../components/CreateSupplierExpenseModal.vue' /* webpackChunkName: "components/create-supplier-expense-modal" */).then(c => wrapFunctional(c.default || c))
export const CurrencyInput = () => import('../../components/CurrencyInput.vue' /* webpackChunkName: "components/currency-input" */).then(c => wrapFunctional(c.default || c))
export const CurrencyInputSituation = () => import('../../components/CurrencyInputSituation.vue' /* webpackChunkName: "components/currency-input-situation" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DefaultDownPayments = () => import('../../components/DefaultDownPayments.vue' /* webpackChunkName: "components/default-down-payments" */).then(c => wrapFunctional(c.default || c))
export const DeleteModal = () => import('../../components/DeleteModal.vue' /* webpackChunkName: "components/delete-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentName = () => import('../../components/DocumentName.vue' /* webpackChunkName: "components/document-name" */).then(c => wrapFunctional(c.default || c))
export const ElectronicSignatureModal = () => import('../../components/ElectronicSignatureModal.vue' /* webpackChunkName: "components/electronic-signature-modal" */).then(c => wrapFunctional(c.default || c))
export const EmailBadge = () => import('../../components/EmailBadge.vue' /* webpackChunkName: "components/email-badge" */).then(c => wrapFunctional(c.default || c))
export const EmailCheckerMessage = () => import('../../components/EmailCheckerMessage.vue' /* webpackChunkName: "components/email-checker-message" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../components/EmptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const EmptyStateSearch = () => import('../../components/EmptyStateSearch.vue' /* webpackChunkName: "components/empty-state-search" */).then(c => wrapFunctional(c.default || c))
export const FeatureLockModal = () => import('../../components/FeatureLockModal.vue' /* webpackChunkName: "components/feature-lock-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureLockWrapper = () => import('../../components/FeatureLockWrapper.vue' /* webpackChunkName: "components/feature-lock-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeedbackDropdown = () => import('../../components/FeedbackDropdown.vue' /* webpackChunkName: "components/feedback-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FeedbackModal = () => import('../../components/FeedbackModal.vue' /* webpackChunkName: "components/feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const FetchError = () => import('../../components/FetchError.vue' /* webpackChunkName: "components/fetch-error" */).then(c => wrapFunctional(c.default || c))
export const FileBadge = () => import('../../components/FileBadge.vue' /* webpackChunkName: "components/file-badge" */).then(c => wrapFunctional(c.default || c))
export const FormulateCurrencyInput = () => import('../../components/FormulateCurrencyInput.vue' /* webpackChunkName: "components/formulate-currency-input" */).then(c => wrapFunctional(c.default || c))
export const FormulateVueTelInput = () => import('../../components/FormulateVueTelInput.vue' /* webpackChunkName: "components/formulate-vue-tel-input" */).then(c => wrapFunctional(c.default || c))
export const GalleryModal = () => import('../../components/GalleryModal.vue' /* webpackChunkName: "components/gallery-modal" */).then(c => wrapFunctional(c.default || c))
export const HelpDropdown = () => import('../../components/HelpDropdown.vue' /* webpackChunkName: "components/help-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HelpSidePanel = () => import('../../components/HelpSidePanel.vue' /* webpackChunkName: "components/help-side-panel" */).then(c => wrapFunctional(c.default || c))
export const HoldbackModal = () => import('../../components/HoldbackModal.vue' /* webpackChunkName: "components/holdback-modal" */).then(c => wrapFunctional(c.default || c))
export const HoldbackSidePanel = () => import('../../components/HoldbackSidePanel.vue' /* webpackChunkName: "components/holdback-side-panel" */).then(c => wrapFunctional(c.default || c))
export const IdempotencyErrorModal = () => import('../../components/IdempotencyErrorModal.vue' /* webpackChunkName: "components/idempotency-error-modal" */).then(c => wrapFunctional(c.default || c))
export const ImageCropper = () => import('../../components/ImageCropper.vue' /* webpackChunkName: "components/image-cropper" */).then(c => wrapFunctional(c.default || c))
export const IncomesExportModal = () => import('../../components/IncomesExportModal.vue' /* webpackChunkName: "components/incomes-export-modal" */).then(c => wrapFunctional(c.default || c))
export const InputWithSearch = () => import('../../components/InputWithSearch.vue' /* webpackChunkName: "components/input-with-search" */).then(c => wrapFunctional(c.default || c))
export const InviteModal = () => import('../../components/InviteModal.vue' /* webpackChunkName: "components/invite-modal" */).then(c => wrapFunctional(c.default || c))
export const InvoicesArchivesSidePanel = () => import('../../components/InvoicesArchivesSidePanel.vue' /* webpackChunkName: "components/invoices-archives-side-panel" */).then(c => wrapFunctional(c.default || c))
export const LoginModal = () => import('../../components/LoginModal.vue' /* webpackChunkName: "components/login-modal" */).then(c => wrapFunctional(c.default || c))
export const MinPlanBadge = () => import('../../components/MinPlanBadge.vue' /* webpackChunkName: "components/min-plan-badge" */).then(c => wrapFunctional(c.default || c))
export const Mq = () => import('../../components/Mq.vue' /* webpackChunkName: "components/mq" */).then(c => wrapFunctional(c.default || c))
export const Notes = () => import('../../components/Notes.vue' /* webpackChunkName: "components/notes" */).then(c => wrapFunctional(c.default || c))
export const OnlinePaymentModal = () => import('../../components/OnlinePaymentModal.vue' /* webpackChunkName: "components/online-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentBadge = () => import('../../components/PaymentBadge.vue' /* webpackChunkName: "components/payment-badge" */).then(c => wrapFunctional(c.default || c))
export const PaymentExportModal = () => import('../../components/PaymentExportModal.vue' /* webpackChunkName: "components/payment-export-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentModal = () => import('../../components/PaymentModal.vue' /* webpackChunkName: "components/payment-modal" */).then(c => wrapFunctional(c.default || c))
export const PercentSlider = () => import('../../components/PercentSlider.vue' /* webpackChunkName: "components/percent-slider" */).then(c => wrapFunctional(c.default || c))
export const PhoneBadge = () => import('../../components/PhoneBadge.vue' /* webpackChunkName: "components/phone-badge" */).then(c => wrapFunctional(c.default || c))
export const PlanBadge = () => import('../../components/PlanBadge.vue' /* webpackChunkName: "components/plan-badge" */).then(c => wrapFunctional(c.default || c))
export const PlanningDeleteModal = () => import('../../components/PlanningDeleteModal.vue' /* webpackChunkName: "components/planning-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const PlansModal = () => import('../../components/PlansModal.vue' /* webpackChunkName: "components/plans-modal" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const QuoteImportModal = () => import('../../components/QuoteImportModal.vue' /* webpackChunkName: "components/quote-import-modal" */).then(c => wrapFunctional(c.default || c))
export const QuotesArchivesSidePanel = () => import('../../components/QuotesArchivesSidePanel.vue' /* webpackChunkName: "components/quotes-archives-side-panel" */).then(c => wrapFunctional(c.default || c))
export const ReadMore = () => import('../../components/ReadMore.vue' /* webpackChunkName: "components/read-more" */).then(c => wrapFunctional(c.default || c))
export const ReadonlyErrorModal = () => import('../../components/ReadonlyErrorModal.vue' /* webpackChunkName: "components/readonly-error-modal" */).then(c => wrapFunctional(c.default || c))
export const ReferralConditionsModal = () => import('../../components/ReferralConditionsModal.vue' /* webpackChunkName: "components/referral-conditions-modal" */).then(c => wrapFunctional(c.default || c))
export const RoleBadge = () => import('../../components/RoleBadge.vue' /* webpackChunkName: "components/role-badge" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionOffer = () => import('../../components/SubscriptionOffer.vue' /* webpackChunkName: "components/subscription-offer" */).then(c => wrapFunctional(c.default || c))
export const SupplierInvoiceModal = () => import('../../components/SupplierInvoiceModal.vue' /* webpackChunkName: "components/supplier-invoice-modal" */).then(c => wrapFunctional(c.default || c))
export const SupplierInvoiceProjectsModal = () => import('../../components/SupplierInvoiceProjectsModal.vue' /* webpackChunkName: "components/supplier-invoice-projects-modal" */).then(c => wrapFunctional(c.default || c))
export const SupplierInvoiceSidePanel = () => import('../../components/SupplierInvoiceSidePanel.vue' /* webpackChunkName: "components/supplier-invoice-side-panel" */).then(c => wrapFunctional(c.default || c))
export const SupplierInvoices = () => import('../../components/SupplierInvoices.vue' /* webpackChunkName: "components/supplier-invoices" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TaxModal = () => import('../../components/TaxModal.vue' /* webpackChunkName: "components/tax-modal" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const TopBarCloseButton = () => import('../../components/TopBarCloseButton.vue' /* webpackChunkName: "components/top-bar-close-button" */).then(c => wrapFunctional(c.default || c))
export const TransactionStatusBadge = () => import('../../components/TransactionStatusBadge.vue' /* webpackChunkName: "components/transaction-status-badge" */).then(c => wrapFunctional(c.default || c))
export const TrialLockModal = () => import('../../components/TrialLockModal.vue' /* webpackChunkName: "components/trial-lock-modal" */).then(c => wrapFunctional(c.default || c))
export const TrialModal = () => import('../../components/TrialModal.vue' /* webpackChunkName: "components/trial-modal" */).then(c => wrapFunctional(c.default || c))
export const UnlockModal = () => import('../../components/UnlockModal.vue' /* webpackChunkName: "components/unlock-modal" */).then(c => wrapFunctional(c.default || c))
export const UserBadge = () => import('../../components/UserBadge.vue' /* webpackChunkName: "components/user-badge" */).then(c => wrapFunctional(c.default || c))
export const UserDropdown = () => import('../../components/UserDropdown.vue' /* webpackChunkName: "components/user-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UserDropdownMenu = () => import('../../components/UserDropdownMenu.vue' /* webpackChunkName: "components/user-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const BankAccountCard = () => import('../../components/Bank/BankAccountCard.vue' /* webpackChunkName: "components/bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const BankAccountEditModal = () => import('../../components/Bank/BankAccountEditModal.vue' /* webpackChunkName: "components/bank-account-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const BankAccountNeedValidationMessage = () => import('../../components/Bank/BankAccountNeedValidationMessage.vue' /* webpackChunkName: "components/bank-account-need-validation-message" */).then(c => wrapFunctional(c.default || c))
export const BankNeedScaMessage = () => import('../../components/Bank/BankNeedScaMessage.vue' /* webpackChunkName: "components/bank-need-sca-message" */).then(c => wrapFunctional(c.default || c))
export const CalendarBlockCreateEvent = () => import('../../components/calendar/CalendarBlockCreateEvent.vue' /* webpackChunkName: "components/calendar-block-create-event" */).then(c => wrapFunctional(c.default || c))
export const CalendarBlockDropdown = () => import('../../components/calendar/CalendarBlockDropdown.vue' /* webpackChunkName: "components/calendar-block-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CalendarConfigModal = () => import('../../components/calendar/CalendarConfigModal.vue' /* webpackChunkName: "components/calendar-config-modal" */).then(c => wrapFunctional(c.default || c))
export const CalendarContainer = () => import('../../components/calendar/CalendarContainer.vue' /* webpackChunkName: "components/calendar-container" */).then(c => wrapFunctional(c.default || c))
export const CalendarEventDropdown = () => import('../../components/calendar/CalendarEventDropdown.vue' /* webpackChunkName: "components/calendar-event-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CalendarMonth = () => import('../../components/calendar/CalendarMonth.vue' /* webpackChunkName: "components/calendar-month" */).then(c => wrapFunctional(c.default || c))
export const CalendarMonthDay = () => import('../../components/calendar/CalendarMonthDay.vue' /* webpackChunkName: "components/calendar-month-day" */).then(c => wrapFunctional(c.default || c))
export const CalendarMonthDayBlock = () => import('../../components/calendar/CalendarMonthDayBlock.vue' /* webpackChunkName: "components/calendar-month-day-block" */).then(c => wrapFunctional(c.default || c))
export const CalendarWeek = () => import('../../components/calendar/CalendarWeek.vue' /* webpackChunkName: "components/calendar-week" */).then(c => wrapFunctional(c.default || c))
export const CalendarWeekDay = () => import('../../components/calendar/CalendarWeekDay.vue' /* webpackChunkName: "components/calendar-week-day" */).then(c => wrapFunctional(c.default || c))
export const CalendarWeekDayBlock = () => import('../../components/calendar/CalendarWeekDayBlock.vue' /* webpackChunkName: "components/calendar-week-day-block" */).then(c => wrapFunctional(c.default || c))
export const CalendarWeekDayBlockHour = () => import('../../components/calendar/CalendarWeekDayBlockHour.vue' /* webpackChunkName: "components/calendar-week-day-block-hour" */).then(c => wrapFunctional(c.default || c))
export const News = () => import('../../components/news/News.vue' /* webpackChunkName: "components/news" */).then(c => wrapFunctional(c.default || c))
export const NewsDetails = () => import('../../components/news/NewsDetails.vue' /* webpackChunkName: "components/news-details" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../../components/news/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/notifications/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const NotificationsContainer = () => import('../../components/notifications/NotificationsContainer.vue' /* webpackChunkName: "components/notifications-container" */).then(c => wrapFunctional(c.default || c))
export const NotificationsItem = () => import('../../components/notifications/NotificationsItem.vue' /* webpackChunkName: "components/notifications-item" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoryButtonNew = () => import('../../components/product/ProductCategoryButtonNew.vue' /* webpackChunkName: "components/product-category-button-new" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoryLink = () => import('../../components/product/ProductCategoryLink.vue' /* webpackChunkName: "components/product-category-link" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoryModal = () => import('../../components/product/ProductCategoryModal.vue' /* webpackChunkName: "components/product-category-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductCategorySearchResult = () => import('../../components/product/ProductCategorySearchResult.vue' /* webpackChunkName: "components/product-category-search-result" */).then(c => wrapFunctional(c.default || c))
export const ProductExportModal = () => import('../../components/product/ProductExportModal.vue' /* webpackChunkName: "components/product-export-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductSpreadModal = () => import('../../components/product/ProductSpreadModal.vue' /* webpackChunkName: "components/product-spread-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductWorkItemModal = () => import('../../components/product/ProductWorkItemModal.vue' /* webpackChunkName: "components/product-work-item-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductWorkSearch = () => import('../../components/product/ProductWorkSearch.vue' /* webpackChunkName: "components/product-work-search" */).then(c => wrapFunctional(c.default || c))
export const ChargeDownPaymentInvoice = () => import('../../pages/quotes/components/ChargeDownPaymentInvoice.vue' /* webpackChunkName: "components/charge-down-payment-invoice" */).then(c => wrapFunctional(c.default || c))
export const ChargeFinalInvoice = () => import('../../pages/quotes/components/ChargeFinalInvoice.vue' /* webpackChunkName: "components/charge-final-invoice" */).then(c => wrapFunctional(c.default || c))
export const ChargeQuoteConfirmModal = () => import('../../pages/quotes/components/ChargeQuoteConfirmModal.vue' /* webpackChunkName: "components/charge-quote-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const ChargeQuoteModal = () => import('../../pages/quotes/components/ChargeQuoteModal.vue' /* webpackChunkName: "components/charge-quote-modal" */).then(c => wrapFunctional(c.default || c))
export const ChargeQuoteModalDownPaymentInvoiceLine = () => import('../../pages/quotes/components/ChargeQuoteModalDownPaymentInvoiceLine.vue' /* webpackChunkName: "components/charge-quote-modal-down-payment-invoice-line" */).then(c => wrapFunctional(c.default || c))
export const ChargeQuoteModalInvoiceLineGeneric = () => import('../../pages/quotes/components/ChargeQuoteModalInvoiceLineGeneric.vue' /* webpackChunkName: "components/charge-quote-modal-invoice-line-generic" */).then(c => wrapFunctional(c.default || c))
export const ChargeQuoteModalInvoicePlannedLine = () => import('../../pages/quotes/components/ChargeQuoteModalInvoicePlannedLine.vue' /* webpackChunkName: "components/charge-quote-modal-invoice-planned-line" */).then(c => wrapFunctional(c.default || c))
export const ChargeQuoteModalSituationInvoiceLine = () => import('../../pages/quotes/components/ChargeQuoteModalSituationInvoiceLine.vue' /* webpackChunkName: "components/charge-quote-modal-situation-invoice-line" */).then(c => wrapFunctional(c.default || c))
export const ChargeSituationInvoice = () => import('../../pages/quotes/components/ChargeSituationInvoice.vue' /* webpackChunkName: "components/charge-situation-invoice" */).then(c => wrapFunctional(c.default || c))
export const ConfirmChargeFinalBeforeDownPaymentsModal = () => import('../../pages/quotes/components/ConfirmChargeFinalBeforeDownPaymentsModal.vue' /* webpackChunkName: "components/confirm-charge-final-before-down-payments-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateDownPayment = () => import('../../pages/quotes/components/CreateDownPayment.vue' /* webpackChunkName: "components/create-down-payment" */).then(c => wrapFunctional(c.default || c))
export const CreateSituation = () => import('../../pages/quotes/components/CreateSituation.vue' /* webpackChunkName: "components/create-situation" */).then(c => wrapFunctional(c.default || c))
export const DeleteScheduledDownPaymentModal = () => import('../../pages/quotes/components/DeleteScheduledDownPaymentModal.vue' /* webpackChunkName: "components/delete-scheduled-down-payment-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
