import { has as _has } from 'lodash-es'
import { ACCOUNTING_MODE_DISABLED, TYPE_SELF_EMPLOYED, TYPE_SOLE_PROPRIETOR } from '@/constants/companies'
import { frDomCountries } from '@/constants/countries'

export const setTitle = (title) => `${title} - Costructor`

export const sidebar = [
  {
    key: 'main',
    class: 'mb-4',
    items: [
      {
        key: 'dashboard',
        name: 'index',
        route: '/',
        icon: ['fad', 'chart-line'],
      },
      {
        key: 'calendar',
        route: '/calendar',
        icon: ['fad', 'calendar-days'],
      },
      {
        key: 'projects',
        route: '/projects',
        icon: ['fad', 'person-digging'],
      },
    ],
  },
  {
    key: 'sales',
    items: [
      {
        key: 'quotes',
        route: '/quotes',
        icon: ['fad', 'ruler-triangle'],
      },
      {
        key: 'invoices',
        route: '/invoices',
        icon: ['fad', 'file-invoice'],
        items: [
          {
            key: 'credits',
            route: '/credits',
            icon: ['fad', 'hand-holding-dollar'],
          },
          {
            key: 'payments',
            route: '/payments',
            icon: ['fad', 'coins'],
          },
          {
            key: 'holdbacks',
            route: '/holdbacks',
            icon: ['fad', 'building-columns'],
            class: '!mb-4',
          },
        ],
      },
      {
        key: 'contacts',
        route: '/contacts',
        icon: ['fad', 'book-user'],
      },
      {
        key: 'products',
        route: '/products',
        icon: ['fad', 'folders'],
        class: '!mb-4',
      },
    ],
  },
  {
    key: 'buys',
    class: 'mb-6',
    items: [
      {
        key: 'order_forms',
        route: '/order-forms',
        icon: ['fad', 'basket-shopping'],
      },
      {
        key: 'supplier_invoices',
        route: '/supplier-invoices',
        icon: ['fad', 'receipt'],
        items: [
          {
            key: 'supplier_credits',
            route: '/supplier-credits',
            icon: ['fad', 'hand-holding-dollar'],
          },
        ],
      },
      {
        key: 'suppliers',
        route: '/suppliers',
        icon: ['fad', 'industry-windows'],
      },
    ],
  },
  {
    visible: (company, isRoot, can) =>
      //isRoot() ||
      can('plan', 'accounting.transactions') ||
      ([TYPE_SELF_EMPLOYED, TYPE_SOLE_PROPRIETOR].includes(company.value.type) &&
        frDomCountries.includes(company.value.country) &&
        company.value.accountingMode !== ACCOUNTING_MODE_DISABLED),
    key: 'accounting',
    class: 'mb-6',
    items: [
      {
        visible: (company, isRoot, can) => can('role', `transactions.read`) && can('plan', 'accounting.transactions'),
        key: 'transactions',
        route: '/transactions',
        icon: ['fad', 'building-columns'],
        beta: true,
      },
      {
        visible: (company, isRoot, can) =>
          can('role', `transactions.read`) &&
          [TYPE_SELF_EMPLOYED, TYPE_SOLE_PROPRIETOR].includes(company.value.type) &&
          frDomCountries.includes(company.value.country) &&
          company.value.accountingMode !== ACCOUNTING_MODE_DISABLED,
        key: 'incomes',
        route: '/accounting/incomes',
        icon: ['fad', 'book'],
      },
    ],
  },
  {
    key: 'bottom',
    items: [
      {
        key: 'company',
        route: '/account/company',
        icon: ['fad', 'gear'],
        mq: ['xs', 'sm', 'md'],
      },
      {
        key: 'company',
        route: '/account/company/info',
        icon: ['fad', 'gear'],
        mq_exclude: ['xs', 'sm', 'md'],
      },
      {
        key: 'help',
        granted: true,
        click: ($bus) => {
          $bus.emit('openHelp')
        },
        icon: ['fad', 'life-ring'],
        class: 'mb-6',
      },
      {
        key: 'referral',
        granted: true,
        route: '/referral',
        icon: ['fad', 'heart'],
      },
    ],
  },
]

export const companySettingsMenu = {
  account: {
    infos: {
      icon: ['fad', 'building'],
      items: [
        {
          key: 'info',
          name: 'info',
          route: 'info',
          icon: ['fad', 'mailbox'],
        },
        {
          key: 'insurance',
          name: 'insurance',
          route: 'insurance',
          icon: ['fad', 'house-crack'],
        },
      ],
    },
    accounting: {
      icon: ['fad', 'calculator-simple'],
      items: [
        {
          key: 'tax',
          name: 'tax',
          route: 'tax',
          icon: ['fad', 'percent'],
        },
        {
          key: 'profit',
          name: 'profit',
          route: 'profit',
          icon: ['fad', 'gauge-max'],
        },
        {
          key: 'banks',
          name: 'banks',
          route: 'banks',
          icon: ['fad', 'building-columns'],
        },
        {
          visible: (company) => company.accountingMode !== ACCOUNTING_MODE_DISABLED,
          key: 'accounting',
          name: 'accounting',
          route: 'accounting',
          icon: ['fad', 'calculator-simple'],
        },
      ],
    },
  },
  app: {
    sales: {
      icon: ['fad', 'file-contract'],
      items: [
        {
          key: 'quotes',
          name: 'quotes',
          route: 'quotes',
          icon: ['fad', 'ruler-triangle'],
        },
        {
          key: 'invoices',
          name: 'invoices',
          route: 'invoices',
          icon: ['fad', 'receipt'],
        },
        {
          key: 'credits',
          name: 'credits',
          route: 'credits',
          icon: ['fad', 'hand-holding-dollar'],
        },
        /*{
          key: 'clients',
          name: 'clients',
          route: 'clients',
          icon: ['fad', 'book-user'],
        },*/
        {
          key: 'payments',
          name: 'payments',
          route: 'payments',
          icon: ['fad', 'wallet'],
        },
        {
          key: 'terms',
          name: 'terms',
          route: 'terms',
          icon: ['fad', 'file-contract'],
        },
        {
          key: 'products',
          name: 'products',
          route: 'products',
          icon: ['fad', 'folders'],
          can: ['plan', 'products.stock'],
        },
      ],
    },
    buys: {
      icon: ['fad', 'wallet'],
      items: [
        {
          key: 'order_forms',
          name: 'order-forms',
          route: 'order-forms',
          icon: ['fad', 'basket-shopping'],
        },
        /*{
          key: 'suppliers',
          name: 'suppliers',
          route: 'suppliers',
          icon: ['fad', 'industry-windows'],
        },*/
      ],
    },
    subscription: {
      icon: ['fad', 'gear'],
      items: [
        {
          key: 'subscription',
          name: 'subscription',
          route: 'subscription',
          icon: ['fad', 'rocket'],
        },
        {
          key: 'users',
          name: 'users',
          route: 'users',
          icon: ['fad', 'users-gear'],
        },
      ],
    },
    documents: {
      icon: ['fad', 'file'],
      items: [
        {
          key: 'builder',
          name: 'builder',
          route: 'builder',
          icon: ['fad', 'pen'],
        },
        {
          key: 'appearance',
          name: 'appearance',
          route: 'appearance',
          icon: ['fad', 'palette'],
        },
        {
          key: 'footer',
          name: 'footer',
          route: 'footer',
          icon: ['fad', 'file-dashed-line'],
        },
      ],
    },
    services: {
      icon: ['fad', 'palette'],
      items: [
        {
          key: 'emails',
          name: 'emails',
          route: 'emails',
          icon: ['fad', 'envelope'],
        },
        {
          key: 'reminder',
          name: 'reminder',
          route: 'reminder',
          icon: ['fad', 'bell-on'],
        },
      ],
    },
  },
}
