import { get as _get, has as _has } from 'lodash-es'
import { withQuery } from 'ufo'

export default async function ({ redirect, route, $auth, store }) {
  /* if (_has(window, 'gtag')) {
    window.gtag('set', 'page_path', route.fullPath)
    window.gtag('event', 'page_view')
  }*/

  if (typeof $auth === 'undefined') return

  if (!$auth.init.value && !$auth.mustLogin.value) {
    if (!/invites__|logout__|maintenance__/.test(route.name)) {
      await $auth.getLogin()
      store.commit('templates/initState')
    }
  }

  if (!$auth.loggedIn.value) {
    if (
      !/login__|express-id-token|logout__|maintenance__|invites__|signup__|join__|reset-password__|forgot-password__/.test(
        route.name
      )
    ) {
      let redirectTo = ''
      if (route.fullPath !== '/') {
        redirectTo = `?redirect=${route.fullPath}`
      }
      return redirect(`/login${redirectTo}`)
    }
  } else {
    if (/login__|express-id-token|signup__|reset-password__|forgot-password__/.test(route.name)) {
      return redirect('/')
    }
  }

  let matchedRoute = _get(route, 'name', null)
  if (matchedRoute) {
    if (matchedRoute.replace('___fr', '').includes('account-company') && !$auth.can('role', `company.manage`)) {
      return redirect(withQuery(`/${$auth.getHome()}`, _get(route, 'query', {})))
    }
  }

  $auth.getNotifications()

  const isActive = _get($auth, 'subscription.value.status', null) === 'active'
  const isPaid = _get($auth, 'subscription.value.plan.name', 'Starter') !== 'Starter'

  if (_has(window, '$crisp') && typeof $crisp.is === 'function' && $crisp.is('chat:closed')) {
    if (isActive) window.$crisp.push(['do', 'chat:hide'])
    else window.$crisp.push(['do', 'chat:show'])
  }
}
