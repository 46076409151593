//d4c3663c4d3c65fd97d1b7fe0daafd2e676c3940
import Vue from "vue"
import { library, config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import {
  faSpinnerThird as falSpinnerThird,
  faCircleInfo as falCircleInfo,
  faArrowsRepeat as falArrowsRepeat,
  faChevronDown as falChevronDown,
  faClock as falClock,
  faPen as falPen,
  faTrashCan as falTrashCan,
  faXmark as falXmark,
  faRulerTriangle as falRulerTriangle,
  faPersonDigging as falPersonDigging,
  faUser as falUser,
  faHelmetSafety as falHelmetSafety,
  faGear as falGear,
  faChevronCircleRight as falChevronCircleRight,
  faEraser as falEraser,
  faPhone as falPhone,
  faEnvelope as falEnvelope,
  faLockOpen as falLockOpen,
  faPlus as falPlus,
  faCalendarDays as falCalendarDays,
  faLockHashtag as falLockHashtag,
  faRotateLeft as falRotateLeft,
  faCloud as falCloud,
  faCloudXmark as falCloudXmark,
  faCloudCheck as falCloudCheck,
  faRepeat as falRepeat,
  faTruckLadder as falTruckLadder,
  faCalendarDay as falCalendarDay,
  faLink as falLink,
  faListCheck as falListCheck,
  faArrowUpToLine as falArrowUpToLine,
  faArrowDownToLine as falArrowDownToLine,
  faCopy as falCopy,
  faArrowsUpDownLeftRight as falArrowsUpDownLeftRight,
  faArrowUpRightAndArrowDownLeftFromCenter as falArrowUpRightAndArrowDownLeftFromCenter,
  faLock as falLock,
  faChevronRight as falChevronRight,
  faBolt as falBolt,
  faBox as falBox,
  faFileInvoice as falFileInvoice,
  faMinus as falMinus,
  faMoneyCheck as falMoneyCheck,
  faCreditCard as falCreditCard,
  faCoins as falCoins,
  faEuroSign as falEuroSign,
  faBuildingColumns as falBuildingColumns,
  faArrowPointer as falArrowPointer,
  faChevronUp as falChevronUp,
  faPaperclip as falPaperclip,
  faToolbox as falToolbox,
  faMoneyBillTransfer as falMoneyBillTransfer,
  faEye as falEye,
  faPercent as falPercent,
  faFileImport as falFileImport,
  faArrowsRotate as falArrowsRotate,
  faPalette as falPalette,
  faPrint as falPrint,
  faPaperPlane as falPaperPlane,
  faCircleQuestion as falCircleQuestion,
  faRotateExclamation as falRotateExclamation,
  faPencil as falPencil,
  faPlusMinus as falPlusMinus,
  faThumbsUp as falThumbsUp,
  faThumbsDown as falThumbsDown,
  faSignature as falSignature,
  faChevronLeft as falChevronLeft,
  faMagnifyingGlass as falMagnifyingGlass,
  faDownload as falDownload,
  faFileExport as falFileExport,
  faEllipsis as falEllipsis,
  faPenField as falPenField,
  faStamp as falStamp,
  faFile as falFile,
  faBan as falBan,
  faTruck as falTruck,
  faBoxCheck as falBoxCheck,
  faCircleXmark as falCircleXmark,
  faCalendar as falCalendar,
  faClockRotateLeft as falClockRotateLeft,
  faBoxOpen as falBoxOpen,
  faCheck as falCheck,
  faShareNodes as falShareNodes,
  faEnvelopeCircleCheck as falEnvelopeCircleCheck,
  faTriangleExclamation as falTriangleExclamation,
  faCommentSms as falCommentSms,
  faEyeDropper as falEyeDropper,
  faWandMagicSparkles as falWandMagicSparkles,
  faUpload as falUpload,
  faFolderOpen as falFolderOpen,
  faFolder as falFolder,
  faHashtag as falHashtag,
  faArrowLeft as falArrowLeft,
  faReceipt as falReceipt,
  faArrowRight as falArrowRight,
  faFilePdf as falFilePdf,
  faCalendarClock as falCalendarClock,
  faLayerGroup as falLayerGroup,
  faSlidersUp as falSlidersUp,
  faLinkSlash as falLinkSlash,
  faForwardStep as falForwardStep,
  faCircleDollarToSlot as falCircleDollarToSlot,
  faBellOn as falBellOn,
  faEyeSlash as falEyeSlash
} from "@fortawesome/pro-light-svg-icons"

import {
  faCheck as farCheck,
  faSpinnerThird as farSpinnerThird,
  faUpRightFromSquare as farUpRightFromSquare,
  faArrowsRotate as farArrowsRotate,
  faLink as farLink,
  faEllipsisVertical as farEllipsisVertical,
  faLinkSlash as farLinkSlash,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronDown as farChevronDown,
  faUpload as farUpload,
  faIndustryWindows as farIndustryWindows,
  faUser as farUser,
  faEllipsis as farEllipsis,
  faTrashCan as farTrashCan,
  faDiamondTurnRight as farDiamondTurnRight,
  faPlus as farPlus,
  faTriangleExclamation as farTriangleExclamation,
  faXmark as farXmark,
  faCalculatorSimple as farCalculatorSimple,
  faPersonDolly as farPersonDolly,
  faPersonDigging as farPersonDigging,
  faMinus as farMinus,
  faImage as farImage,
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
  faSplit as farSplit,
  faPen as farPen,
  faLockOpen as farLockOpen,
  faFileExcel as farFileExcel,
  faPaperPlane as farPaperPlane,
  faCoins as farCoins,
  faClone as farClone,
  faRotateLeft as farRotateLeft,
  faNoteSticky as farNoteSticky,
  faHourglassStart as farHourglassStart,
  faEnvelope as farEnvelope,
  faFilePdf as farFilePdf,
  faArrowsUpDown as farArrowsUpDown,
  faArrowsToDottedLine as farArrowsToDottedLine,
  faMagnifyingGlass as farMagnifyingGlass,
  faBullhorn as farBullhorn,
  faCircleQuestion as farCircleQuestion,
  faChevronUp as farChevronUp,
  faHourglassHalf as farHourglassHalf,
  faFolderPlus as farFolderPlus,
  faFileExport as farFileExport,
  faBell as farBell,
  faArrowRight as farArrowRight,
  faPhone as farPhone,
  faBarsProgress as farBarsProgress,
  faSquareCheck as farSquareCheck,
  faClipboardListCheck as farClipboardListCheck,
  faTextSize as farTextSize,
  faAlignSlash as farAlignSlash,
  faTextSlash as farTextSlash,
  faEraser as farEraser,
  faBold as farBold,
  faItalic as farItalic,
  faStrikethrough as farStrikethrough,
  faUnderline as farUnderline,
  faAlignLeft as farAlignLeft,
  faAlignCenter as farAlignCenter,
  faAlignJustify as farAlignJustify,
  faAlignRight as farAlignRight,
  faParagraph as farParagraph,
  faH1 as farH1,
  faH2 as farH2,
  faH3 as farH3,
  faListUl as farListUl,
  faListOl as farListOl,
  faFlagCheckered as farFlagCheckered,
  faClockRotateLeft as farClockRotateLeft,
  faThumbsUp as farThumbsUp,
  faSignature as farSignature,
  faThumbsDown as farThumbsDown,
  faBan as farBan,
  faCheckDouble as farCheckDouble,
  faMessageExclamation as farMessageExclamation,
  faCalendarExclamation as farCalendarExclamation,
  faUnlock as farUnlock,
  faTruck as farTruck,
  faBoxCircleCheck as farBoxCircleCheck,
  faToolbox as farToolbox,
  faHelmetSafety as farHelmetSafety,
  faBlockBrick as farBlockBrick,
  faIdBadge as farIdBadge,
  faUserHelmetSafety as farUserHelmetSafety,
  faHammer as farHammer,
  faCreditCard as farCreditCard,
  faCartCircleArrowUp as farCartCircleArrowUp,
  faBox as farBox,
  faDownLeftAndUpRightToCenter as farDownLeftAndUpRightToCenter,
  faExpandWide as farExpandWide,
  faHourglass as farHourglass,
  faClock as farClock,
  faListCheck as farListCheck,
  faEyeSlash as farEyeSlash,
  faEye as farEye
} from "@fortawesome/pro-regular-svg-icons"

import {
  faLock as fasLock,
  faTriangleExclamation as fasTriangleExclamation,
  faFilePdf as fasFilePdf,
  faFilePlus as fasFilePlus,
  faCircleCheck as fasCircleCheck,
  faFileImage as fasFileImage,
  faCheck as fasCheck,
  faPersonDigging as fasPersonDigging,
  faRulerTriangle as fasRulerTriangle,
  faEye as fasEye,
  faStar as fasStar,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faCircleInfo as fasCircleInfo,
  faPlus as fasPlus,
  faReceipt as fasReceipt,
  faUser as fasUser,
  faPen as fasPen,
  faWifiSlash as fasWifiSlash,
  faFloppyDisk as fasFloppyDisk,
  faChevronDown as fasChevronDown,
  faBolt as fasBolt,
  faFolderPlus as fasFolderPlus,
  faFolderArrowUp as fasFolderArrowUp,
  faGripDotsVertical as fasGripDotsVertical,
  faChevronUp as fasChevronUp,
  faEllipsisVertical as fasEllipsisVertical,
  faBox as fasBox,
  faFileInvoice as fasFileInvoice,
  faUserCheck as fasUserCheck,
  faPaperPlane as fasPaperPlane,
  faMinus as fasMinus,
  faPhone as fasPhone,
  faEnvelope as fasEnvelope,
  faEarthEurope as fasEarthEurope,
  faCalendarDays as fasCalendarDays,
  faPlay as fasPlay,
  faBullhorn as fasBullhorn,
  faMessageQuestion as fasMessageQuestion,
  faBarsSort as fasBarsSort,
  faBars as fasBars,
  faFolderXmark as fasFolderXmark,
  faGift as fasGift,
  faBadgePercent as fasBadgePercent,
  faSort as fasSort,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faFileCsv as fasFileCsv,
  faToolbox as fasToolbox,
  faExclamation as fasExclamation
} from "@fortawesome/pro-solid-svg-icons"

import {
  faCalendarClock as fatCalendarClock,
  faCalendarCheck as fatCalendarCheck
} from "@fortawesome/pro-thin-svg-icons"

import {
  faCircleInfo as fadCircleInfo,
  faTriangleExclamation as fadTriangleExclamation,
  faUser as fadUser,
  faRulerTriangle as fadRulerTriangle,
  faReceipt as fadReceipt,
  faBookUser as fadBookUser,
  faPersonDigging as fadPersonDigging,
  faFolders as fadFolders,
  faLightbulb as fadLightbulb,
  faFileInvoice as fadFileInvoice,
  faFileZipper as fadFileZipper,
  faMoneyCheckPen as fadMoneyCheckPen,
  faFilePen as fadFilePen,
  faPaperPlane as fadPaperPlane,
  faShareFromSquare as fadShareFromSquare,
  faFilePdf as fadFilePdf,
  faCalendarClock as fadCalendarClock,
  faBook as fadBook,
  faDownToLine as fadDownToLine,
  faPenField as fadPenField,
  faSortUp as fadSortUp,
  faSortDown as fadSortDown,
  faFolder as fadFolder,
  faFile as fadFile,
  faArrowRightFromBracket as fadArrowRightFromBracket,
  faChartLine as fadChartLine,
  faCalendarDays as fadCalendarDays,
  faHandHoldingDollar as fadHandHoldingDollar,
  faCoins as fadCoins,
  faBuildingColumns as fadBuildingColumns,
  faBasketShopping as fadBasketShopping,
  faIndustryWindows as fadIndustryWindows,
  faGear as fadGear,
  faLifeRing as fadLifeRing,
  faHeart as fadHeart,
  faBuilding as fadBuilding,
  faMailbox as fadMailbox,
  faHouseCrack as fadHouseCrack,
  faCalculatorSimple as fadCalculatorSimple,
  faPercent as fadPercent,
  faGaugeMax as fadGaugeMax,
  faFileContract as fadFileContract,
  faWallet as fadWallet,
  faRocket as fadRocket,
  faUsersGear as fadUsersGear,
  faPen as fadPen,
  faPalette as fadPalette,
  faFileDashedLine as fadFileDashedLine,
  faEnvelope as fadEnvelope,
  faBellOn as fadBellOn,
  faChartColumn as fadChartColumn
} from "@fortawesome/pro-duotone-svg-icons"

import {
  faWhatsapp as fabWhatsapp
} from "@fortawesome/free-brands-svg-icons"

library.add(
  falSpinnerThird,
  falCircleInfo,
  falArrowsRepeat,
  falChevronDown,
  falClock,
  falPen,
  falTrashCan,
  falXmark,
  falRulerTriangle,
  falPersonDigging,
  falUser,
  falHelmetSafety,
  falGear,
  falChevronCircleRight,
  falEraser,
  falPhone,
  falEnvelope,
  falLockOpen,
  falPlus,
  falCalendarDays,
  falLockHashtag,
  falRotateLeft,
  falCloud,
  falCloudXmark,
  falCloudCheck,
  falRepeat,
  falTruckLadder,
  falCalendarDay,
  falLink,
  falListCheck,
  falArrowUpToLine,
  falArrowDownToLine,
  falCopy,
  falArrowsUpDownLeftRight,
  falArrowUpRightAndArrowDownLeftFromCenter,
  falLock,
  falChevronRight,
  falBolt,
  falBox,
  falFileInvoice,
  falMinus,
  falMoneyCheck,
  falCreditCard,
  falCoins,
  falEuroSign,
  falBuildingColumns,
  falArrowPointer,
  falChevronUp,
  falPaperclip,
  falToolbox,
  falMoneyBillTransfer,
  falEye,
  falPercent,
  falFileImport,
  falArrowsRotate,
  falPalette,
  falPrint,
  falPaperPlane,
  falCircleQuestion,
  falRotateExclamation,
  falPencil,
  falPlusMinus,
  falThumbsUp,
  falThumbsDown,
  falSignature,
  falChevronLeft,
  falMagnifyingGlass,
  falDownload,
  falFileExport,
  falEllipsis,
  falPenField,
  falStamp,
  falFile,
  falBan,
  falTruck,
  falBoxCheck,
  falCircleXmark,
  falCalendar,
  falClockRotateLeft,
  falBoxOpen,
  falCheck,
  falShareNodes,
  falEnvelopeCircleCheck,
  falTriangleExclamation,
  falCommentSms,
  falEyeDropper,
  falWandMagicSparkles,
  falUpload,
  falFolderOpen,
  falFolder,
  falHashtag,
  falArrowLeft,
  falReceipt,
  falArrowRight,
  falFilePdf,
  falCalendarClock,
  falLayerGroup,
  falSlidersUp,
  falLinkSlash,
  falForwardStep,
  falCircleDollarToSlot,
  falBellOn,
  falEyeSlash
)

library.add(
  farCheck,
  farSpinnerThird,
  farUpRightFromSquare,
  farArrowsRotate,
  farLink,
  farEllipsisVertical,
  farLinkSlash,
  farChevronLeft,
  farChevronRight,
  farChevronDown,
  farUpload,
  farIndustryWindows,
  farUser,
  farEllipsis,
  farTrashCan,
  farDiamondTurnRight,
  farPlus,
  farTriangleExclamation,
  farXmark,
  farCalculatorSimple,
  farPersonDolly,
  farPersonDigging,
  farMinus,
  farImage,
  farArrowUp,
  farArrowDown,
  farSplit,
  farPen,
  farLockOpen,
  farFileExcel,
  farPaperPlane,
  farCoins,
  farClone,
  farRotateLeft,
  farNoteSticky,
  farHourglassStart,
  farEnvelope,
  farFilePdf,
  farArrowsUpDown,
  farArrowsToDottedLine,
  farMagnifyingGlass,
  farBullhorn,
  farCircleQuestion,
  farChevronUp,
  farHourglassHalf,
  farFolderPlus,
  farFileExport,
  farBell,
  farArrowRight,
  farPhone,
  farBarsProgress,
  farSquareCheck,
  farClipboardListCheck,
  farTextSize,
  farAlignSlash,
  farTextSlash,
  farEraser,
  farBold,
  farItalic,
  farStrikethrough,
  farUnderline,
  farAlignLeft,
  farAlignCenter,
  farAlignJustify,
  farAlignRight,
  farParagraph,
  farH1,
  farH2,
  farH3,
  farListUl,
  farListOl,
  farFlagCheckered,
  farClockRotateLeft,
  farThumbsUp,
  farSignature,
  farThumbsDown,
  farBan,
  farCheckDouble,
  farMessageExclamation,
  farCalendarExclamation,
  farUnlock,
  farTruck,
  farBoxCircleCheck,
  farToolbox,
  farHelmetSafety,
  farBlockBrick,
  farIdBadge,
  farUserHelmetSafety,
  farHammer,
  farCreditCard,
  farCartCircleArrowUp,
  farBox,
  farDownLeftAndUpRightToCenter,
  farExpandWide,
  farHourglass,
  farClock,
  farListCheck,
  farEyeSlash,
  farEye
)

library.add(
  fasLock,
  fasTriangleExclamation,
  fasFilePdf,
  fasFilePlus,
  fasCircleCheck,
  fasFileImage,
  fasCheck,
  fasPersonDigging,
  fasRulerTriangle,
  fasEye,
  fasStar,
  fasCaretDown,
  fasCaretRight,
  fasCircleInfo,
  fasPlus,
  fasReceipt,
  fasUser,
  fasPen,
  fasWifiSlash,
  fasFloppyDisk,
  fasChevronDown,
  fasBolt,
  fasFolderPlus,
  fasFolderArrowUp,
  fasGripDotsVertical,
  fasChevronUp,
  fasEllipsisVertical,
  fasBox,
  fasFileInvoice,
  fasUserCheck,
  fasPaperPlane,
  fasMinus,
  fasPhone,
  fasEnvelope,
  fasEarthEurope,
  fasCalendarDays,
  fasPlay,
  fasBullhorn,
  fasMessageQuestion,
  fasBarsSort,
  fasBars,
  fasFolderXmark,
  fasGift,
  fasBadgePercent,
  fasSort,
  fasChevronLeft,
  fasChevronRight,
  fasFileCsv,
  fasToolbox,
  fasExclamation
)

library.add(
  fatCalendarClock,
  fatCalendarCheck
)

library.add(
  fadCircleInfo,
  fadTriangleExclamation,
  fadUser,
  fadRulerTriangle,
  fadReceipt,
  fadBookUser,
  fadPersonDigging,
  fadFolders,
  fadLightbulb,
  fadFileInvoice,
  fadFileZipper,
  fadMoneyCheckPen,
  fadFilePen,
  fadPaperPlane,
  fadShareFromSquare,
  fadFilePdf,
  fadCalendarClock,
  fadBook,
  fadDownToLine,
  fadPenField,
  fadSortUp,
  fadSortDown,
  fadFolder,
  fadFile,
  fadArrowRightFromBracket,
  fadChartLine,
  fadCalendarDays,
  fadHandHoldingDollar,
  fadCoins,
  fadBuildingColumns,
  fadBasketShopping,
  fadIndustryWindows,
  fadGear,
  fadLifeRing,
  fadHeart,
  fadBuilding,
  fadMailbox,
  fadHouseCrack,
  fadCalculatorSimple,
  fadPercent,
  fadGaugeMax,
  fadFileContract,
  fadWallet,
  fadRocket,
  fadUsersGear,
  fadPen,
  fadPalette,
  fadFileDashedLine,
  fadEnvelope,
  fadBellOn,
  fadChartColumn
)

library.add(
  fabWhatsapp
)

config.autoAddCss = false
Vue.component("fa-icon", FontAwesomeIcon)