import { cloneDeep as _cloneDeep, get as _get, has as _has, pick as _pick } from 'lodash-es'
import { toValue } from '@vueuse/core'
import { roundToSix, roundToThree, roundToTwo } from '@/utils/numbers'
import { add, isValid } from 'date-fns'

import {
  PRODUCT_TYPE_SUBCONTRACT,
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPE_WORKFORCE,
  PRODUCT_TYPES,
  PRODUCT_TYPES_WORKS,
} from '@/constants/products'
import {
  AMOUNT_TYPE_PERCENT,
  AMOUNT_TYPE_SUBTOTAL,
  DOCUMENT_SUBTYPE_DOWN_PAYMENT,
  DOCUMENT_SUBTYPE_FINAL,
  DOCUMENT_OPTIONS,
  DOCUMENT_OPTION_DISPLAY_NUMBERING,
  DOCUMENT_OPTION_DISPLAY_REFERENCE,
  DOCUMENT_SUBTYPE_SITUATION,
  DOCUMENT_STATUS_CANCELLED,
  LINE_TYPE_GROUP,
  DISCOUNT_TYPE_PERCENT,
  DISCOUNT_TYPE_AMOUNT,
  QUOTE,
  LINE_TYPE_TEXT,
  AMOUNT_TYPE_TOTAL,
  LINE_TYPE_PRODUCT,
  LINE_TYPE_SUBTOTAL,
  LINE_TYPE_PAGE_BREAK,
  LINE_TYPE_LINE_BREAK,
} from '@/constants/documents'
import { PRODUCT_TYPE_WORK } from '../constants/products'
import { ROUNDING_METHOD_LINES, ROUNDING_METHOD_TOTAL } from '@/constants/companies'
import { nanoid } from 'nanoid/non-secure'
import { useDocumentDeductions } from '@/composables/document'
import { computed } from '@nuxtjs/composition-api'
import { stripHTML } from '@/utils/normalizers'

export const getDefaultState = () => ({
  // Internal document state
  company: null,
  units: [],
  planItems: [],
  settings: {
    roundMethod: 'line',
    globalVat: false,
  },
  dragging: false,
  options: Object.entries(DOCUMENT_OPTIONS).reduce((obj, [k, v]) => {
    for (const scope of v) {
      obj[`${scope}_${k}`] = false
    }
    return obj
  }, {}),
  init: false,
  dirty: false,
  saving: false,
  closing: false,
  deleting: false,
  pending: false,
  broken: false,
  confirmCloseRevision: false,
  touched: false,
  readOnly: false,
  activeLine: null,
  autofocusLine: null,
  activeGroup: null,
  // Actual document data
  id: null,
  lockedId: null,
  version: 0,
  object: null,
  objectType: null,
  pickup: false,
  revision: null,
  revisionNumber: 0,
  revisionReason: null,
  quote: null,
  final: false,
  lines: [],
  visibleLines: [],
  tree: [],
  invoicesSummary: [], // Situations invoices
  childTree: {},
  availablePaymentMethodTypes: [],
  vatAttestation: null,
  taxable: true,
  electronicSignature: false,
  onlinePayment: false,
  deductedDocuments: [],
  paymentMethods: [],
  downPayments: [],
  downPaymentsDeduction: 'subtotal',
  downPaymentsProrated: false,
  totalDeductions: [],
  subtotalDeductions: [],
  amountOff: null,
  percentOff: null,
  holdback: null,
  holdbackReleasedAt: null,
  discountType: DISCOUNT_TYPE_PERCENT,
  reverseCharge: false,
  roundingMethod: ROUNDING_METHOD_LINES,
  purchaseOrderNumber: null,
  number: null,
  forceNumber: false,
  subNumber: null,
  footer: null,
  paymentConditions: null,
  vatNotice: null,
  assignee: null,
  customer: null,
  supplier: null,
  address: {
    street: '',
    complement: '',
    postal_code: '',
    city: '',
    country: 'fr',
  },
  addressLabel: null,
  deliveryAddress: {
    street: '',
    complement: '',
    postal_code: '',
    city: '',
    country: 'fr',
  },
  deliveryAddressLabel: null,
  currency: 'EUR',
  projectLabel: null,
  project: null,
  attachmentsCount: 0,
  description: null,
  name: null,
  parent: null,
  children: [],
  status: 'draft',
  type: null,
  subtype: null,
  detailed: false,
  workDuration: null,
  workDurationUnit: 'day',
  remainingSubtotal: 0,
  remainingTotal: 0,
  createdAt: new Date(),
  issuedAt: null,
  expireAt: add(new Date(), { months: 1 }),
  paymentDelay: '30d',
  bankAccount: null,
  beginAt: null,
  preVisitAt: null,
  deliverAt: null,
  updatedAt: new Date(),
})

export const DOCUMENT_FIELDS_TYPE_NUMERIC = ['amountOff', 'percentOff', 'workDuration']
export const DOCUMENT_FIELDS_TYPE_DATE = ['beginAt', 'preVisitAt', 'deliverAt', 'createdAt', 'expireAt', 'issuedAt']
export const DOCUMENT_FIELDS_NULLABLE = ['purchaseOrderNumber']

export const DOCUMENT_FIELDS_READ_ONLY = [
  'taxable',
  'taxAmounts',
  'object',
  'objectType',
  'deductedDocuments',
  'revision',
  'detailed',
  'fromQuote',
  'status',
  'revisionNumber',
  'vatAttestation',
  'attachmentsCount',
  'createdAt',
  'subNumber',
  'quote',
  'final',
  'currency',
  'remainingSubtotal',
  'remainingTotal',
]

export const DOCUMENT_FIELDS_WRITE_ONLY = ['lockedId', 'revisionReason']
export const DOCUMENT_FIELDS_PROCESS = [
  'type',
  'subtype',
  'subtype',
  'number',
  'version',
  'forceNumber',
  'assignee',
  'customer',
  'supplier',
  'address',
  'addressLabel',
  'deliveryAddress',
  'deliveryAddressLabel',
  'pickup',
  'project',
  'projectLabel',
  'downPayments',
  'downPaymentsDeduction',
  'downPaymentsProrated',
  'totalDeductions',
  'subtotalDeductions',
  'amountOff',
  'percentOff',
  'holdback',
  'holdbackReleasedAt',
  'discountType',
  'paymentMethods',
  'electronicSignature',
  'onlinePayment',
  'reverseCharge',
  'purchaseOrderNumber',
  'footer',
  'paymentConditions',
  'vatNotice',
  'description',
  'name',
  'beginAt',
  'preVisitAt',
  'deliverAt',
  'issuedAt',
  'expireAt',
  'paymentDelay',
  'bankAccount',
  'parent',
  'description',
  'workDuration',
  'workDurationUnit',
  'options',
  'tax',
  'roundingMethod',
]

export const getCompanyTax = (company) => {
  switch (company.country) {
    case 'mf':
      return 'TGCA'
    case 'nc':
      return 'TGC'
    default:
      return 'TVA'
  }
}

export const getRandomString = (start) => {
  return Math.random().toString(36).substring(start)
}

export const convertSupplies = (supplies, params) => {
  let newSupplies = supplies
  let buyPrices = null
  let sellPrices = null

  if (params.from === PRODUCT_TYPE_WORK || params.to === PRODUCT_TYPE_WORK_DETAILED) {
    newSupplies = supplies.map((supply) => {
      // Don't convert if current supply already has element.name
      if (_has(supply, 'element.name')) {
        return supply
      }

      return {
        element: {
          name: supply.name
            ? params.from === PRODUCT_TYPE_WORK
              ? `<div>${supply.name}</div>`
              : stripHTML(supply.name)
            : supply.name,
          buyPrice: _get(supply, 'element.buyPrice', null),
          reference: _get(supply, 'element.reference', null),
          sellPrice: _get(supply, 'element.sellPrice', null),
          id: _get(supply, 'element.id', null),
          unit: _get(supply, 'element.unit', _get(params, 'unit', params.defaultUnit)),
          type: _get(supply, 'element.type', PRODUCT_TYPE_SUPPLY),
        },
        key: supply.key || nanoid(),
        quantity: supply.quantity || 1,
        isNew: supply.isNew || params.from === PRODUCT_TYPE_WORK,
      }
    })

    sellPrices = newSupplies
      .filter((el) => el.element.name)
      .reduce((total, item) => {
        if (item.element && item.element.sellPrice) {
          total += Math.round(item.element.sellPrice * item.quantity * 100) / 100
        }
        return total
      }, 0)

    buyPrices = newSupplies
      .filter((el) => el.element.name)
      .reduce((total, item) => {
        if (item.element && item.element.buyPrice) {
          total += Math.round(item.element.buyPrice * item.quantity * 100) / 100
        }
        return total
      }, 0)
  }

  if (params.from === PRODUCT_TYPE_WORK_DETAILED) {
    newSupplies = supplies.map((supply) => ({ ...supply, name: stripHTML(_get(supply, 'element.name', '')) }))
  }

  return { supplies: newSupplies, sellPrices, buyPrices }
}

// Can't use composables here
const defaultWorkItem = (params = {}) => ({
  element: {
    name: '',
    buyPrice: null,
    sellPrice: null,
    reference: null,
    id: null,
    unit: _get(params, 'unit', null),
    type: PRODUCT_TYPE_SUPPLY,
  },
  key: nanoid(),
  id: null,
  quantity: 1,
  lockSellPrice: false,
  isNew: true,
})

export const getLineIndex = (state, key) => state.lines.findIndex((line) => line.key === key)

export const getLineData = (state, key) => state.lines.find((line) => line.key === key)

/**
 * Creates a new empty line filled with some defaults.
 */
export const newLine = (params = {}, units) => {
  if (!params.key) {
    params.key = getRandomString(4)
  }

  const persist = _get(params, 'persist', false)
  const isNew = _get(params, 'isNew', true)

  const type = params.type || 'product'

  let data = {
    description: _get(params, 'description', null),
    reference: _get(params, 'reference', null),
    type: type,
    key: params.key,
    id: _get(params, 'id', null),
    isNew,
    persist,
    editable: _get(params, 'editable', true),
    additional: _get(params, 'additional', false),
    dirty: false,
    // isDuplicate: _get(params, 'isDuplicate', false),
  }

  if (type === LINE_TYPE_GROUP) {
    data = {
      ...data,
      ...{ lines: [], optional: _get(params, 'optional', false), collapsed: _get(params, 'collapsed', false) },
      //optional: _get(params, 'optional', false),
    }
  } else {
    if (![LINE_TYPE_LINE_BREAK, LINE_TYPE_PAGE_BREAK].includes(type)) {
      data = {
        ...data,
        ...{
          images: _get(params, 'images', []),
          imagesPosition: _get(params, 'imagesPosition', 'bottom'),
          imagesSize: _get(params, 'imagesSize', 'md'),
          tax: _get(params, 'tax', null),
          unit: _get(params, 'unit', null),
          product: _get(params, 'product', null),
          optional: _get(params, 'optional', false),
          quantity: _get(params, 'quantity', 1),
          percent: _get(params, 'percent', 0),
          quoteQuantity: _get(params, 'quoteQuantity', null),
          invoicedQuantity: _get(params, 'invoicedQuantity', null),
          initial: _get(params, 'initial', undefined),
          invoicedPercent: _get(params, 'invoicedPercent', null),
          showDiscount: _get(params, 'showDiscount', false),
          discountType: _get(params, 'discountType', DISCOUNT_TYPE_PERCENT),
          amountOff: Number(_get(params, 'amountOff', 0)),
          percentOff: Number(_get(params, 'percentOff', 0)),
          sellPrice: parseInt(_get(params, 'sellPrice', 0)),
          buyPrice: parseInt(_get(params, 'buyPrice', 0)),
          source: _get(params, 'source', undefined),
          sourceId: _get(params, 'sourceId', undefined),
        },
      }
    }

    if (data.quoteQuantity && data.quantity) {
      data.percent = (data.quantity / data.quoteQuantity) * 100
    }

    if (data.invoicedQuantity && data.quoteQuantity) {
      data.invoicedPercent = (data.invoicedQuantity / data.quoteQuantity) * 100
    }

    if (type === 'product') {
      const productType = _get(params, 'productType', PRODUCT_TYPE_SUPPLY)

      let supplies = _get(params, 'supplies', null)

      if (PRODUCT_TYPES_WORKS.includes(productType) && supplies === null) {
        supplies = [
          productType === PRODUCT_TYPE_WORK_DETAILED
            ? defaultWorkItem({ unit: _get(params, 'unit', null) })
            : { name: '' },
        ]
      }

      /*if (Array.isArray(supplies)) {
        let lastSupply = supplies[supplies.length - 1]

        if (
          !lastSupply ||
          (productType === PRODUCT_TYPE_WORK_DETAILED && _get(lastSupply, 'element.name', null) !== '') ||
          (productType === PRODUCT_TYPE_WORK && _get(lastSupply, 'name', null) !== '')
        ) {
          supplies.push(
            PRODUCT_TYPE_WORK_DETAILED ? defaultWorkItem({ unit: _get(params, 'unit', null) }) : { name: '' }
          )
        }
      }*/

      if (productType === PRODUCT_TYPE_WORK_DETAILED) {
        supplies.forEach((supply) => {
          if (supply.element && typeof supply.element.unit === 'string') {
            let currentUnit = units.find((el) => el.id === supply.element.unit)
            if (currentUnit) {
              supply.element.unit = currentUnit
            }
          }

          if (typeof supply.lockSellPrice === 'undefined') {
            supply.lockSellPrice = false
          }
        })
      }

      if (productType === PRODUCT_TYPE_WORK) {
        if (_get(supplies, `${supplies.length - 1}.name`) !== '') {
          supplies.push({ name: '' })
        }
      }

      if (_has(params, 'work')) {
        data.work = params.work
      }

      data = {
        ...data,
        ...{
          productType,
          sellPriceLocked: _get(params, 'sellPriceLocked', productType === PRODUCT_TYPE_WORK_DETAILED),
          // Diffs when saving document to avoid unnecessary backend processing
          originalTax: _get(params, 'tax', null),
          originalUnit: _get(params, 'unit', null),
          supplies,
        },
      }

      let planItem = _get(params, 'planItem', null)
      if (planItem) data.planItem = planItem.id
    }
  }

  return data
}

export const countHours = (line, quantity = 0) => {
  switch (_get(line, 'unit.symbol')) {
    case 'm':
      let qty = quantity || line.quantity
      if (qty > 0) {
        return roundToTwo(qty / 60)
      }
      return 0
    case 'h':
      return quantity || line.quantity
    case 'j':
      return 7 * (quantity || line.quantity)
    case 'mois':
      return 7 * 20 * (quantity || line.quantity)
    default:
      return 0
  }
}

export const getLineMargin = (line) => {
  if (!line.sellPrice || !line.buyPrice) {
    return null
  }

  if (line.sellPrice > 0 && line.buyPrice > 0) {
    return Math.round((margin / line.buyPrice) * 100)
  }

  return null
}

const normalizeLines = (currentTree, state) => {
  const numericFields = ['quantity', 'buyPrice', 'sellPrice']
  const meta = ['originalTax', 'originalUnit', 'dirty']
  const lines = state.lines

  return currentTree.reduce((arr, key) => {
    let lineIndex = lines.findIndex((el) => el.key === key)
    if (lineIndex !== -1) {
      let lineData = Object.entries(lines[lineIndex]).reduce((obj, [key, val]) => {
        if (!meta.includes(key)) {
          switch (true) {
            /*case key === 'description':
              if (typeof val === 'string') {
                const blocks = [...val.matchAll(/<div>(.*?)<\/div>/g)].map((el) => _get(el, 1, '')).filter(Boolean)
                if (blocks.length === 1) val = blocks[0]
              }
              obj[key] = val
              break*/

            // Sends only the IDs of objects
            case key === 'tax':
            case key === 'unit':
            case key === 'planItem':
              // Caused an error if product doesn't have a default tax rate and document is taxable
              //if (lines[lineIndex].isNew || lines[lineIndex].originalTax !== val) {
              obj[key] = _get(val, 'id', val)
              //}
              break

            case key === 'supplies':
              try {
                val = JSON.parse(
                  JSON.stringify(val, (key, v) => {
                    if (['unit'].includes(key)) {
                      return _get(v, 'id', v)
                    }
                    return v
                  })
                )

                let pos = 0
                val = val.map((el) => {
                  el.position = pos
                  pos++
                  return el
                })
              } catch (err) {
                val = []
              }

              if (val === null) val = []

              obj[key] = val
                .filter((el) => (el.element && el.element.name !== '') || (el.name && el.name !== ''))
                .map((supply) => {
                  // Converts supplies before saving (avoids storing unused data if switching between PRODUCT_TYPE_WORK_DETAILED and PRODUCT_TYPE_WORK)
                  if (lines[lineIndex].productType === PRODUCT_TYPE_WORK_DETAILED) {
                    supply.name = undefined
                  }
                  if (lines[lineIndex].productType === PRODUCT_TYPE_WORK) {
                    supply = { name: supply.name }
                  }

                  return supply
                })

              /*if (lines[lineIndex].productType === PRODUCT_TYPE_WORK) {
                obj[key] = val.filter((el) => el.name !== '')
              } else {
                obj[key] = []
              }*/
              break

            case key === 'images':
              obj[key] = val.map((img) => img.id)
              break

            case ['product'].includes(key):
              obj[key] = _get(val, 'id', val)
              break

            // Send numeric fields as floats
            case numericFields.includes(key):
              obj[key] = parseFloat(val)
              break

            default:
              obj[key] = val
          }
        }

        return obj
      }, {})

      if (lineData.type === 'text') {
        lineData = _pick(lineData, [
          'id',
          'type',
          'key',
          'description',
          'reference',
          'isNew',
          'images',
          'imagesPosition',
          'editable',
          'persist',
          'additional',
          'productType',
        ])
      }

      if ([LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK].includes(lineData.type)) {
        lineData = _pick(lineData, ['id', 'type', 'key', 'isNew', 'editable', 'persist', 'additional'])
      }

      if (!PRODUCT_TYPES_WORKS.includes(lineData.productType)) {
        lineData.supplies = undefined
        lineData.elements = undefined
      }

      if (_has(state.childTree, lineData.key)) {
        lineData.lines = normalizeLines(state.childTree[lineData.key], state)
      }

      arr.push(lineData)
    }

    return arr
  }, [])
}

export const getDownPaymentValue = function (total, downPayment) {
  if ([AMOUNT_TYPE_SUBTOTAL, AMOUNT_TYPE_TOTAL].includes(downPayment.type)) return downPayment.amount
  return Math.round((total * downPayment.amount) / 100)
}

export const getDeductionValue = function (total, deduction) {
  if ([AMOUNT_TYPE_SUBTOTAL, AMOUNT_TYPE_TOTAL].includes(deduction.amountType)) return deduction.amount
  return Math.round((total * deduction.amount) / 100)
}

/**
 * Normalizes lines from the store
 */
export const getNormalizedLines = (state) => {
  const tree = state.tree
  return normalizeLines(tree, state)
}

export const formatTax = (tax, showName = false) => {
  if (tax && tax.exception === true && tax.code && showName === true) {
    switch (tax.code) {
      case 'exempt':
        return 'Aucune'
      case 'crossborder':
        return 'Intracom.'
      case 'extracom':
        return 'Extracom.'
      case 'mixed':
        return 'Multitaux'
      default:
        return tax ? formatTaxAmount(tax.rate / 100) + ' %' : ''
    }
  }
  return tax ? formatTaxAmount(tax.rate / 100) + ' %' : ''
}

export const formatTaxAmount = (val) => {
  if (val === null) {
    val = 0
  }

  if (typeof val === 'number') {
    val = val.toFixed(2)
  }

  return val.toString().replace('.', ',').replace(',00', '')
}

export const formatUnit = (unit) => _get(unit, 'symbol', '')

export const formatNumber = (val) => {
  if (val === null) {
    val = 0
  }

  if (typeof val === 'number') {
    val = val.toFixed(2)
  }

  return val.toString().replace('.', ',').replace(',00', '')
}
