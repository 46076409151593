//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'

import { useUser } from '@/composables/user'
import { useState } from 'vuex-composition-helpers'

export default defineComponent({
  props: {
    isModal: Boolean,
  },
  setup(props) {
    const { user, company, subscription } = useUser()

    const fullName = computed(() => `${user.value.firstName || ''} ${user.value.lastName || ''}`.trim())

    const store = useState(['impersonate'])
    const impersonate = computed(() => store.impersonate.value)

    const closeImpersonate = () => {
      window.location.href = '/'
    }

    return { props, user, fullName, impersonate, subscription, company, closeImpersonate }
  },
})
